import {
    Box,
    Button, Card, Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import { useServices } from 'cng-web-lib'
import {CIAApiUrls} from "../../../../../apiUrls";

const useStyles = makeStyles(theme => ({
    topMenuLabel: {
        padding: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#8996AF'
    },
    bottomBtn: {
        padding: theme.spacing(1.25),
        marginTop: theme.spacing(3),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topMenu: {
        padding: theme.spacing(1.25),
        margin: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    buttonTransform: {
        textTransform: 'none'
    },
}))

function UploadInfoCard(props) {

    const classes = useStyles()
    const { securedSendRequest } = useServices()

    const [loading, setLoading] = useState(false);

    function bytesToSize(bytes, decimals) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function analyseDocAjax() {

        setLoading(true)

        let url = CIAApiUrls.MASS_RECOMMEND_ANALYSE_DOC;
        let data = new FormData();
        data.append('uuid', props.docFileDetail.uuid);
        securedSendRequest.execute("POST", url, data,
            (response) => {
                setTimeout(() => {
                    props.setUploadState('analyse')
                    setLoading(false)
                }, 5000)
            },
            (error) => {
                console.error(error)
                setLoading(false)
            }
        )
    }

    return (
        <div>
            <Box className={classes.topMenu}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#5E81F4" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>1</span>

                        </span>
                    <span style={{fontWeight: 'bold'}}>Upload & Analyse</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#1C1D2133" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>2</span>

                        </span>
                    <span>Finish</span>
                </div>
            </Box>

            <Box mb={1.5}>
                <Divider />
            </Box>

            <Box className={classes.topMenuLabel}>
                <span><FontAwesomeIcon icon={['fal', 'info-circle']} /> There are {props.docFileDetail.records} entries inside this file. The amount of transactions to be performed will be {props.requestType == 'both' ? props.docFileDetail.records*2 : props.docFileDetail.records}.</span>
            </Box>

            <Box component={Card} mx={1.5} mb={3} px={3} >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <FontAwesomeIcon icon={['fal', 'file-alt']} />
                        </ListItemAvatar>
                        <ListItemText primary={props.docFileDetail.fileName} secondary={bytesToSize(props.docFileDetail.fileSize,2 )} />
                        <ListItemText disableTypography
                                      primary={<Typography variant="body2" color="textSecondary">Name</Typography>}
                                      secondary={<Typography variant="body1" color="textPrimary">{props.docFileDetail.productName}</Typography>} />
                        <ListItemText disableTypography
                                      primary={<Typography variant="body2" color="textSecondary">Trade direction type</Typography>}
                                      secondary={<Typography variant="body1" color="textPrimary">{props.requestType == 'both' ? 'Import & export country' : capitalize(props.requestType)}</Typography>} />
                    </ListItem>
                </List>
            </Box>{/* Card */}

            <Box className={classes.bottomBtn}>
                <Button className={classes.buttonTransform} variant="outlined" color="primary" onClick={() => props.setUploadState('uploading')}>Discard</Button>
                <Button className={classes.buttonTransform} disabled={loading} endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />} variant="contained" color="primary" onClick={() => analyseDocAjax()}>Analyse</Button>
            </Box>
        </div>

    );
}

export default UploadInfoCard