import {
  Box,
  CircularProgress,
  Grid
} from '@material-ui/core'
import React from 'react'

import { FadedButton } from 'src/components/button'

function Retrieving(props) {

  return (<Box my={9} mx={3}>
    <Grid
      container
      direction='column'
      spacing={2}
      alignItems='center'
      justify='center'
    >
      <Grid item><CircularProgress /></Grid>
      <Grid item>
        Retrieving results...
      </Grid>
      <Grid item>
        <FadedButton
          onClick={props.onBack}
          customColor='text.error'
        >
          Back to calculator
        </FadedButton>
      </Grid>
    </Grid>
  </Box>)
}

export default Retrieving
