import {
  Box,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,

  makeStyles, Menu, MenuItem, ListItemIcon, Button
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CngTabBar from '../../../../components/tabs'
import Overview from './Overview'
import Duties from './Duties'
import Documents from './Documents'
import ErrorContent from '../components/ErrorContent'
import {formatOverviewData, formatDuties, formatDocs, formatText} from './helper'
import {components, useServices} from "cng-web-lib";
import {CIAApiUrls} from "../../../../apiUrls";
import clsx from "clsx";
import {FileText, LogOut} from "react-feather";
import {useHistory} from "react-router-dom";
import pathMap from "../../../../paths/PathMap_CalistaAdvisory";

const {
  button: { CngPrimaryButton }
} = components

const useStyles = makeStyles(theme => ({
  header: {
    background: 'rgba(28, 29, 33, 0.04)',
    padding: theme.spacing(1.25),
    borderRadius: 8,
    margin: theme.spacing(.5),
    fontWeight: 'bold',
    fontSize: '1.4em',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
    '& > svg': { margin: theme.spacing(0, 1) }
  },
  exportBtn: {
    margin: theme.spacing(.5),
  },
  tabs: {
    margin: theme.spacing(1, 0)
  },
  lightBtn: {
    color: theme.palette.primary.main,
    backgroundColor: '#5E81F419',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  footerButton: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  footerRightButton: {
    display: 'flex',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexBasic: 0,
    paddingRight: theme.spacing(1)
  }
}))

const toolbarStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiMenu': {
      '&-paper': { borderRadius: 10 },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.9em',
          padding: '8px 10px',
          minWidth: '12em',
          '&:hover': {
            backgroundColor:
            theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
      theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  }
}))

const PUBLIC_URL = process.env.PUBLIC_URL

function EAdvisory(props) {
  const classes = useStyles()
  const toolbarClasses = toolbarStyles()

  const history = useHistory()

  const [activeTab, setActiveTab] = useState(0)
  const [data, setData] = useState({
    overview: {},
    duties: [],
    docs: {},
    controls: {}
  })
  const [error, setError] = useState(null)
  const [expanded, setExpanded] = React.useState({ import : false, export : false });

  useEffect(() => {
    formatData()
  }, [props])

  const formatData = () => {
    if (props.data.status === 200) {
      setData({
        overview: formatOverviewData(props.data),
        duties: formatDuties(convertDutiesJSON()),
        docs: formatDocs(convertDocJSON()),
        //controls: formatControl(props)
      })
    }

    else
      setError(props.data.errorMessage)
  }

  const { securedSendRequest } = useServices()

  function convertDocJSON() {
    return {
      documents:
      [
        {
          doctype: "General",
          docs: [
            {
              docSet: formatDocList(props.data.documents.importerDocuments),
              docControlType: "IMPORT"
            },
            {
              docSet: formatDocList(props.data.documents.exporterDocuments),
              docControlType: "EXPORT"
            }
          ]
        },
        {
          doctype: "Specific",
          docs: [
            {
              docSet: [],
              docControlType: "IMPORT"
            },
            {
              docSet: [],
              docControlType: "EXPORT"
            }
          ]
        }
      ]
    }
  }

  function convertDutiesJSON() {
    return {
      taxAndDuties: getDutyTaxSet()
    }
  }

  function getDutyTaxSet() {
    let taxAndDuties = []
    props.data.duties.forEach(d => {
      let taxDuty = {
        groupName: d.dutyGroupName,
        tax: getDutyGroup(d.dutyGroupDetails)
      }
      taxAndDuties.push(taxDuty)
    })

    return taxAndDuties
  }

  function getDutyGroup(details) {
    let taxList = []
    details.forEach(d => {
      let tax = {
        specific_duties_title: d.title,
        specific_duties_description: d.description,
        specific_duties_additional_note: d.additionalNotes
      }
      taxList.push(tax)
    })

    return taxList
  }

  function formatDocList(docList) {
    let docSet = []
    docList.forEach(d => {
      let doc = {
        docDescription: d.description,
        docName: d.title,
        docLink: d.fileUrl,
        docResponsibleParty: d.responsibleParty,
        purpose: d.purpose,
        docCategory: d.category,
        source: d.source
      }
      docSet.push(doc)
    })

    return docSet
  }


  function formatDocLink() {
    props.data.result.documents.forEach(d => {
      d.docs.forEach(docs => {
        docs.docSet.forEach(docSet => {
          if(docSet.docLink != null && docSet.docLink != "") {
            let url = new URL(docSet.docLink)
            let isInternal = url.pathname.startsWith('/cal-cia/tas/')
            let link = isInternal ? url.href.replace(url.origin + '/cal-cia', process.env.REACT_APP_CIA_ORIGIN_URL) : docSet.docLink
            docSet.docLink = link
          }
        })
      })
    })
  }

  function onComplete() {
    console.log("complete")
  }

  const generateReport = (fileType) => {

    console.log(props)

    let urlLink = "";
    let fileExtension = "";

    if(fileType === "pdf") {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_PDF_V2;
      fileExtension = "pdf";
    } else if(fileType === "excel") {
      urlLink = CIAApiUrls.EADVISORY_EXPORT_EXCEL_V2;
      fileExtension = "xlsx";
    }

    securedSendRequest.execute(
        "POST",
        urlLink,
        props.data,
        (exportData) => {
          let blob = exportData.data
          let timeStamp = new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
          let hsCode = props.data.importHsCode != '' ? props.data.importHsCode : props.data.exportHsCode
          let fileName = "eAdvisory_Result_[" + hsCode  + "]_[" + timeStamp + "]."+fileExtension;

          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        },
        error,
        onComplete,
        { responseType: "blob" }

    )
  }

  const renderTabs = () => {
    if (error)
      return <ErrorContent image={PUBLIC_URL + '/static/images/graphics/unknown_error.svg'}>
        {Object.entries(error).map(([code, msg], i) =>
          <Alert severity="error" key={i}>
            <AlertTitle>{code}</AlertTitle>
            {msg.map((m, mi) => <React.Fragment key={mi}>
              <span dangerouslySetInnerHTML={{
                __html: formatText(m)
              }} />
              {mi < msg.length - 1 && <br/>}
            </React.Fragment>)}
          </Alert>
        )}
      </ErrorContent>
    switch (activeTab) {
      case 1:
        return <Duties duties={data.duties} />
      case 2:
        return <Documents docs={data.docs} />
      default:
        return <Overview {...data.overview} expanded={expanded} setExpanded={setExpanded} />
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNewSearch = () => {
    history.replace("/");
    setTimeout(() => {
      history.replace(pathMap.CALISTA_ADVISORY_WIZARD);
    }, 10);
  }

  const handleExpandAll = () => {
    setExpanded({import:true, export:true})
  }

  const handleCollapseAll = () => {
    setExpanded({import:false, export:false})
  }

  return (<>
    <Box className={classes.header}>
      <Tooltip title='Back to search results'>
        <IconButton className='cng-cia' onClick={props.onClose}>
          <FontAwesomeIcon icon={['fal', 'arrow-left']} size='sm' />
        </IconButton>
      </Tooltip>

      <Box className={classes.title}>
        <FontAwesomeIcon icon={['fal', 'chart-network']} />
        eAdvisory
      </Box>
      <Box>
        <Tooltip title='Export' placement='bottom'>
          <IconButton color={'default'} onClick={handleClick}>
            <LogOut size='16px' />
          </IconButton>
        </Tooltip>
        <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            className={clsx(toolbarClasses.menu)}
        >
          <MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
            {'Export as'}
          </MenuItem>
          <MenuItem onClick={() => generateReport("pdf")}>
            <ListItemIcon>
              <FileText size='16px' />
            </ListItemIcon>
            <Typography variant='inherit' style={{ fontSize: 'small' }}>
              PDF
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => generateReport("excel")}>
            <ListItemIcon>
              <FileText size='16px' />
            </ListItemIcon>
            <Typography variant='inherit' style={{ fontSize: 'small' }}>
              EXCEL
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
    <Grid container direction='column' spacing={2}>
      {!error && (
        <Grid item>
          <CngTabBar className={classes.tabs}
            tabs={['Overview', 'Duties & Taxes', 'Trade Documents']}
            onActiveTabChanged={setActiveTab}
          />
        </Grid>
      )}

      <Grid item>
        <Box component={Card} p={1.25} m={1.5}>
          {renderTabs()}
        </Box>{/* Card */}
      </Grid>

      <Box className={classes.footerButton}>
        <Button className={classes.lightBtn}
                variant='contained'
                startIcon={<FontAwesomeIcon icon={['fal', 'arrows-to-line']} fixedWidth />}
                onClick={handleCollapseAll}
        >
          Collapse All
        </Button>

        <Button className={classes.lightBtn}
                variant='contained'
                startIcon={<FontAwesomeIcon icon={['fal', 'arrows-from-line']} fixedWidth />}
                onClick={handleExpandAll}
        >
          Expand All
        </Button>

        <Button className={classes.lightBtn}
                variant='contained'
                startIcon={<FontAwesomeIcon icon={['fal', 'folder-arrow-down']} fixedWidth />}
                onClick={handleClick}
        >
          Download Report
        </Button>
        <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            className={clsx(toolbarClasses.menu)}
        >
          <MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
            {'Export as'}
          </MenuItem>
          <MenuItem onClick={() => generateReport("pdf")}>
            <ListItemIcon>
              <FileText size='16px' />
            </ListItemIcon>
            <Typography variant='inherit' style={{ fontSize: 'small' }}>
              PDF
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => generateReport("excel")}>
            <ListItemIcon>
              <FileText size='16px' />
            </ListItemIcon>
            <Typography variant='inherit' style={{ fontSize: 'small' }}>
              EXCEL
            </Typography>
          </MenuItem>
        </Menu>

        <Box className={classes.footerRightButton}>
          <CngPrimaryButton
              size='large'
              startIcon={<FontAwesomeIcon icon={['fal', 'search']} fixedWidth size='sm' />}
              onClick={handleNewSearch}
          >
            New Search
          </CngPrimaryButton>
        </Box>
      </Box>
    </Grid>
  </>)
}

export default EAdvisory
