import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

  makeStyles
} from '@material-ui/core'
import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { formatText } from '../helper'

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(-1.5, -2, -2, -5)
  },
  table: {
    '& th': {
      fontWeight: 'bold'
    },
    '& tbody': {
      '& td': {
        borderBottomColor: 'transparent'
      },
      '& tr:nth-child(odd)': {
        background: 'rgba(236, 245, 255, 0.6)'
      }
    }
  }
}))

const initColumns = colData => colData.map(col => {
  let colItem = col
  if (col instanceof Array) {
    let [name, title] = col
    colItem = {name, title}
  }

  return colItem
})

function ProcedureContent(props) {
  const classes = useStyles()

  const columns = useMemo(() => initColumns(props.columns), [props.columns])



  return (<Box className={classes.container}>
    {props.children && (<Box px={2} py={1}>{props.children}</Box>)}
    <Table size='small' className={classes.table}>
      <TableHead><TableRow>
        {props.indexed && <TableCell>Index</TableCell>}
        {columns.map((c, i) =>
          <TableCell key={i}>{c.title || c}</TableCell>
        )}
      </TableRow></TableHead>
      <TableBody>
        {props.data.map((r, i) => (
          <TableRow key={i} hover>
            {props.indexed && <TableCell>{i+1}</TableCell>}
            {columns.map((c, ci) =>
              <TableCell key={ci}
                dangerouslySetInnerHTML={{
                  __html: formatText(r[c.name || c])
                }}
              />
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>)
}

export default ProcedureContent
