import {
    Paper,
    Box
} from '@material-ui/core'
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import AnalyseCard from "./components/AnalyseCard";
import UploadFileCard from "./components/UploadFileCard";
import UploadInfoCard from "./components/UploadInfoCard";


function MassRecommendUpload(props) {
    let { requestType } = useParams();
    const [uploadState, setUploadState] = useState('uploading')
    const [docFileDetail, setDocFileDetail] = useState({ fileName: "", fileSize: "", productName: "", uuid: "", records: "" } )

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname)
    },[])

    return (
        <Paper>
            <Box p={1.5}>
                {uploadState == 'uploading' && <UploadFileCard
                    requestType={requestType}
                    setUploadState={setUploadState}
                    setDocFileDetail={setDocFileDetail} />}
                {uploadState == 'uploaded' && <UploadInfoCard
                    requestType={requestType}
                    setUploadState={setUploadState}
                    docFileDetail={docFileDetail} />}
                {uploadState == 'analyse' && <AnalyseCard
                    requestType={requestType}
                    setUploadState={setUploadState}
                    docFileDetail={docFileDetail} />}
            </Box>
        </Paper>);
}

export default MassRecommendUpload
