import {
  Box,
  Grid
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'



function ControlHeader(props) {

  return (
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item xs>{props.controlsTitle}</Grid>
      <Box component={Grid} item
        color={props.controlsApplied ? 'error.light' : 'success.light'}
      >
        <FontAwesomeIcon icon={['fal', 'dot-circle']} />
        &ensp;{props.controlsApplied ? 'Controls apply' : 'No controls'}
      </Box>
    </Grid>
  )
}

export default ControlHeader
