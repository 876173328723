import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Markdown from 'react-markdown'  

const useStyles = makeStyles(theme => ({
    customTable: {
        borderCollapse: 'collapse',
        '& table': {
          borderCollapse: 'collapse',
          border: '1px solid black',
        },
        '& th, & td': {
          border: '1px solid black',
          padding: '4px',
          textAlign: 'center',
        },
      },
  }))

function ExplanatoryNotesContent(props) {
    const classes = useStyles()
    return (
        <div className={classes.customTable}>
            <Markdown>{props.details}</Markdown>
        </div>
    )
  }
  
  export default ExplanatoryNotesContent
  