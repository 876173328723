import {
  makeStyles
} from '@material-ui/core'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { formatText } from '../helper'

const useStyles = makeStyles(theme => ({
  table: {
    marginLeft: theme.spacing(-4),
    marginTop: theme.spacing(-1.5),
    '& th': {
      verticalAlign: 'top',
      minWidth: '12em'
    },
    '& td, & th': {
      padding: theme.spacing(1.25, 1)
    }
  }
}))

const formatData = data => data.map(d => {
  let title = d.title.trim()
  if (title.endsWith(":"))
    title = title.substring(0, title.length - 1)

  let description = d.description || "<i class='hint'>N.A.</i>"
  if (title.startsWith("Reference")) // split references
    description = description.replaceAll("; ", "<br/>")
  return {...d, title, description}
})

function ControlContent(props) {
  const classes = useStyles()

  const formattedData = useMemo(() => formatData(props.data), [props.data])

  return (<table className={classes.table}><tbody>
    {formattedData.map((r, i) => <tr key={i}>
      <th>{r.title}</th>
      <td dangerouslySetInnerHTML={{
        __html: formatText(r.description)
      }} />
    </tr>)}
  </tbody></table>)
}

export default ControlContent
