import {
  Box,
  CardMedia as MuiCardMedia,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  media: {
    backgroundSize: 'contain',
    margin: theme.spacing(6, 2)
  }
}))

function CardMedia(props) {
  const classes = useStyles()
  return <Box component={MuiCardMedia} {...props}
    className={classes.media + (props.className ? ' ' + props.className : '')}
  />
}
CardMedia.defaultProps = {
  height: 438
}
export default CardMedia
