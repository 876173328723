import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Step,

  makeStyles
} from '@material-ui/core'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import { CngStep } from 'src/components/stepper'
import { FadedButton } from 'src/components/button'
import CargoForm from './CargoForm'
import ChargesForm from './ChargesForm'
import { NextButton, ResetButton } from './components'

import { INITIAL_VALUES, VALIDATION_SCHEMA } from './form'
import { LandedCostContext, handleErrors } from 'src/store/intelligent-advisory'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'
import { CIAApiUrls } from 'src/apiUrls'

import { components, constants, useServices } from 'cng-web-lib'
import moment from 'moment';


const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    CngForm
  },
  button: { CngPrimaryButton },
  stepper: { CngStepper }
} = components
const { FormState } = constants

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormLabel-asterisk': { color: theme.palette.text.error }
  },
  stepper: {
    margin: '0 auto',
    padding: theme.spacing(2.5, 2),
    minWidth: '70vh'
  },
  buttons: {
    padding: theme.spacing(.75, 3),
    minWidth: '8em'
  }
}))

const STEPS = [{
  label: 'Cargo & shipment',
  Component: CargoForm
}, {
  label: 'Freight & other charges',
  Component: ChargesForm
}]

function Calculator(props) {
  const classes = useStyles()
  const history = useHistory()
  const { fetchRecords } = useServices()
  const { enqueueSnackbar } = useSnackbar()
  const {
    formValuesMemory, setFormValuesMemory,
    setDisplayResult, editCriteria, setEditCriteria
  } = useContext(LandedCostContext)

  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const Page = useMemo(() => STEPS[activeStep].Component, [activeStep])
  const nextButtonProps = useMemo(() => {
    if (activeStep === STEPS.length - 1) {
      if (isLoading)
        return {
          children: 'Calculate',
          disabled: true,
          endIcon: <FontAwesomeIcon icon={['fal', 'spinner-third']} spin fixedWidth />
        }
      else
        return {
          children: 'Calculate',
          isLastStep: true
        }
    } else
      { return { children: 'Next' } }
  }, [activeStep, isLoading])

  useEffect(() => {
    if(editCriteria) {
      setFormValuesMemory(formValuesMemory)
    } else {
      setDisplayResult(null)
      setFormValuesMemory(null)
    }
  }, [])

  const handleStep = values => {
    if (values) { // is last step
      setIsLoading(true)
      let oneShipmentPerPersonPerDay = 'Y'
      if (values.importCountry === 'US')
        oneShipmentPerPersonPerDay =  values.shipment ? 'Y' : 'N'

      values.importDate = moment(values.importDate).format('DD/MM/YYYY')
      fetchRecords.execute(
        CIAApiUrls.LANDED_COST_V2,
        { customData: {
          ...values,
          importHSCode: values.hsItem.hsCode,
          inputCurrencyCode: values.currencyCode,
          oneShipmentPerPersonPerDay
        } },
        data => {
          setFormValuesMemory(values)
          console.log(data)
          setDisplayResult(data)
          history.push(pathMap.CALISTA_ADVISORY_LCC_RESULT)
        },
        err => handleErrors(enqueueSnackbar, err),
        () => setIsLoading(false)
      )
    } else
      { setActiveStep(activeStep+1) }
  }

  return (<Card>
    <Box display='flex'>
      <CngStepper className={classes.stepper}
        activeStep={activeStep}
      >
        {STEPS.map(({label}, i) => <Step key={i}>
          <CngStep label={label} />
        </Step>)}
      </CngStepper>
    </Box>
    <Divider />
    <CngForm
      formikProps={{
        initialValues: formValuesMemory || INITIAL_VALUES,
        validationSchema: VALIDATION_SCHEMA[activeStep],
        onReset: () => setActiveStep(0)
      }}
      formState={FormState.COMPLETED}
      renderBodySection={() => <>
        <CardContent className={classes.root}>
          <Page />
        </CardContent>
        <Divider />

      </>}
      renderButtonSection={() => <Box component={CardActions} p={2}>
        <Grid container spacing={1}>
          <Grid item xs>
            <ResetButton className={classes.buttons} activeStep={activeStep} />
          </Grid>
          {activeStep > 0 && <Grid item>
            <FadedButton className={classes.buttons}
              color='primary'
              onClick={()=>setActiveStep(activeStep-1)}
            >
              Back
            </FadedButton>
          </Grid>}
          <Grid item>
            <NextButton
              className={classes.buttons}
              {...nextButtonProps}
              onStep={handleStep}
            />
          </Grid>
        </Grid>
      </Box>}
    />
  </Card>)
}

export default Calculator
