import {
    Box,
    Grid
} from '@material-ui/core'
import React from 'react'  
  
function ExplanatoryNotesHeader(props) {
    return (
      <Grid container spacing={1} wrap='nowrap'>
        <Grid item xs>{props.title||"No Details Available..."}</Grid>
        <Box component={Grid} item
          color={props.controlsApplied ? 'error.light' : 'success.light'}
        >
        </Box>
      </Grid>
    )
  }
  
  export default ExplanatoryNotesHeader
  