import {Box, Button, Card, Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import pathMap from "../../../../../paths/PathMap_CalistaAdvisory";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    bottomBtn: {
        padding: theme.spacing(1.25),
        marginTop: theme.spacing(3),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    topMenuLabel: {
        padding: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#8996AF'
    },
    analyseLabel: {
        padding: theme.spacing(1.25),
        fontWeight: 'bold',
        fontSize: "medium",
        display: 'flex',
        alignItems: 'center'
    },
    topMenu: {
        padding: theme.spacing(1.25),
        margin: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    marginRight: {
        textTransform: 'none',
        marginRight: theme.spacing(1.25)
    },
    buttonTransform: {
        textTransform: 'none'
    },
    listItemEnd: {
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center'
    },
}))

function AnalyseCard(props) {

    const classes = useStyles()
    const history = useHistory()

    const handleClickHome = () => {
        history.push(pathMap.CALISTA_ADVISORY_MASSRECOMMEND)
    }

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function getDate() {
        let now = new Date();
        let formatDate = moment(now.getTime()).format('dddd, DD MMM yyyy');
        console.log(formatDate)

        return formatDate
    }

    function getTime() {
        let now = new Date();
        let formatTime = moment(now.getTime()).format('hh:mm A');
        console.log(formatTime)

        return formatTime
    }


    return (
        <div>
            <Box className={classes.topMenu}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#5E81F4" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>1</span>

                        </span>
                    <span style={{fontWeight: 'bold'}}>Upload & Analyse</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#5E81F4" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>2</span>

                        </span>
                    <span style={{fontWeight: 'bold'}}>Finish</span>
                </div>
            </Box>

            <Box mb={1.5}>
                <Divider />
            </Box>

            <Box className={classes.analyseLabel}>
                <Box mr={1.5}>Analysing file </Box>
                <FontAwesomeIcon icon={['fas', 'sync']} color="#5E81F4"/>
            </Box>

            <Box className={classes.topMenuLabel}>
                <span>We’ll let you know via email once it’s done, please check back in a short while. Created on <b>{getDate()}</b> at <b>{getTime()}</b></span>
            </Box>

            <Box component={Card} mx={1.5} mb={3} px={3} >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={11}>
                            <ListItem>
                                <ListItemAvatar>
                                    <FontAwesomeIcon icon={['fal', 'file-alt']} />
                                </ListItemAvatar>
                                <ListItemText primary={props.docFileDetail.fileName} secondary={props.docFileDetail.records + " entries · " + props.docFileDetail.fileName + " . " + (props.requestType == 'both' ? 'Import & export country' : capitalize(props.requestType))} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={1}>
                            <ListItem className={classes.listItemEnd}>
                                <span style={{fontWeight: 'bold', color: '#8996AF'}}>1%</span>
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Box>{/* Card */}

            <Box className={classes.bottomBtn}>
                <Button className={classes.marginRight} variant="outlined" color="primary" onClick={() => props.setUploadState('uploading')}>Add another request</Button>
                <Button className={classes.buttonTransform} variant="contained" color="primary" onClick={() => handleClickHome()}>Manage mass recommendations</Button>
            </Box>
        </div>

    );
}

export default AnalyseCard
