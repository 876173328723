import { BASIC_ITEM } from 'src/store/intelligent-advisory/tas'

const checkEqualItem = (existing, current) => {
  return existing.hs_code === current.hs_code
    && existing.type === current.type
    && existing.indent === current.indent
    && existing.hs_description === current.hs_description
    && existing.str_confid === current.str_confid
}

export const COLOR = {
  origin: '#F4BE5E',
  destination: '#7CE7AC'
}

// Get separated HS Code from string
export function getHSCode(code) {
  if (!code)
    return []
  let ret = []
  ret.push(code.slice(0,2))
  if (code.length > 2)
    ret.push(code.slice(2,4))
  if (code.length > 4)
    ret.push(code.slice(4,6))
  if (code.length > 6)
    ret.push(code.slice(6))

  return ret;
}

const findAbandonedParent = (item, ...lists) => {
  if (!item || !lists)
    return false
  let parent
  lists.flat().filter(l => l).find(l => {
    if (l.children) {
      // check if children contains possible parent
      let possibleParents = l.children.filter(c => c.indent < item.indent)
      if (possibleParents.length)
        parent = findAbandonedParent(item, possibleParents)
    }
    if (!parent && l.indent < item.indent) {
      if (item.hs_code && l.hs_code && item.hs_code.startsWith(l.hs_code))
        parent = l
      // handle fillers
    }

    if (parent)
      return true
  })
  // return parent
  // result of `find` not returned directly as it will return the ancestor, not direct parent
  return parent
}
const findFillerParent = (item, list) => {
  if (!item || !list)
    return false
  let closest = {indent: -1}
  list.filter(l => l.indent < item.indent) // only search through potential parents
    .forEach(l => {
      if ((item.indent - l.indent) < (item.indent - closest.indent)) {
        if (!item.hs_code || (item.hs_code && !l.hs_code))
          closest = l
      }
    })
  if (closest.indent == -1)
    return false
  return closest
}
const flattenDescendants = item => {
  let ret = [item]
  if (item.children)
    ret.push(...item.children.flatMap(flattenDescendants))
  return ret
}
const findDirectParent = (item, ancestor) => {
  if (!ancestor.children)
    return ancestor
  let potential = ancestor.children.filter(c => c.indent < item.indent)
  if (!potential.length)
    return ancestor

  potential = potential.flatMap(flattenDescendants)
    .filter(p => p.indent < item.indent)
    .sort((p1, p2) => { // sort in reverse indent order
      if (p1.indent > p2.indent)
        return -1
      return (p1.indent < p2.indent) ? 1 : 0
    })
  if (!item.hs_code) // item is a filler.
    return findFillerParent(item, potential)

  let check, closest = {indent: -1}
  while (check = potential.shift()) {
    // prioritse matching HS codes and indents
    if (check.indent >= closest.indent) {
      if (check.hs_code) {
        if (item.hs_code.startsWith(check.hs_code))
          closest = check
      } else if (!closest.hs_code)
        { closest = check }
    }
  }
  if (closest.indent != -1)
    return closest
}

const addToChildren = (item, parent) => {
  item.parentId = parent.id
  if (!parent.children)
    parent.children = []
  parent.hasChildren = true
  parent.children.push(item)
}

/**
* Sets manual terms if necessary, and makes sure expandable items are displayed as such.
*/
const repassHandleChildren = (item, list, terms, expanded, lastChild) => {
  if (item.children) {
    item.hasChildren = true
    let children
    [children, expanded, lastChild] = repass(item.children, terms, expanded, lastChild)
    item.children = children
    if (list.length === 1 && children.length !== 1)
      lastChild = item
  }
  return [item, list, terms, expanded, lastChild]
}
const repass = (list, terms, expanded, lastChild) => {
  if (!list || !list.length)
    return [list, expanded, lastChild]
  list.forEach(item => {
    if (terms && item.type !== 'dutiable' && !item.hasChildren) {
      item.hasChildren = true
      item.isManual = true
      item.terms = terms
    }

    if (!terms && list.length === 1) {
      expanded.push(item.id)
      if (!item.children && item.type === 'dutiable')
        lastChild = item
    }
    [item, list, terms, expanded, lastChild] = repassHandleChildren(item, list, terms, expanded, lastChild)
  })
  return [list, expanded, lastChild]
}

export const predictive = {
  organize: (dataset, manualTerms) => {
    let ret = [], done = []
    dataset.results.forEach(data => {
      ret.push(...predictive.getChildren(data.results, manualTerms, ret, done))
    })
    return repass(ret, manualTerms, [])
  },
  getChildren: (list, manualTerms, resultList, processedList) => {
    let sorted = []

    // filter out those below current indent level
    if (manualTerms && manualTerms.indent)
      list = list.filter(item => item.indent >= manualTerms.indent)

    let item
    while (item = list.shift()) {
      // check for existing duplicates
      let duplicate = [resultList, sorted].flat().find(r => checkEqualItem(r, item))
      if (duplicate)
        item = duplicate
      else
        item = {
          id: organizer.generateRandomNum() + '',
          ...item
        }

      // check if item's parent is in pending items
      let parent = findAbandonedParent(item, list)
      if (parent) { // throw back into queue and end this iteration
        // get closest parent at this point
        if (parent = findFillerParent(item, processedList))
          item.ancestor = parent
        list.push(item)
      } else {
        parent = findAbandonedParent(item, sorted, resultList)
        // item's parent is not in sorted list either.
        if (!parent) // try last filler
          parent = findFillerParent(item, processedList)

        if ((item.indent - parent.indent) > 1) // item's parent was in sorted list.
          // check if indent is correct, otherwise parent may have been last filler
          parent = findFillerParent(item, processedList) || parent

        if (parent) {
          // check if parent needs to be moved
          if (item.ancestor && item.ancestor.indent < parent.indent) {
            let grandparent = findDirectParent(parent, item.ancestor)
            if (grandparent && parent.parentId != grandparent.id) {
              // remove from old grandparent
              let oldParent = processedList.find(p => p.id === parent.parentId)
              if (oldParent.children)
                oldParent.children = oldParent.children.filter(p => p.id !== parent.id)

              // move to new grandparent
              addToChildren(parent, grandparent)

            }
          }

          addToChildren(item, parent)
        } else if (!duplicate) // unique item doesn't have a parent. add directly to SORTED list.
          { sorted.push(item) }

        // add to DONE list
        processedList.unshift(item)
      }
    }
    return sorted
  }
}

export const manual = {
  search: (result, terms) =>
    predictive.organize(result, {
      ...terms,
      griLevel: result.griLevel,
      griNextLevel: result.griNextLevel,
      searchType: result.searchType,
      Description: result.translated_description,
      targetCountry: result.targetCountry,
      inputLanguage: result.inputLanguage,
      outputLanguage: result.outputLanguage
//      ,
//      sessionToken:activeDirection === ORIGIN ? originToken : destinationToken
    })
}

export const organizer = {
  autoExpandList: [], lastChildren: {},
  resetAutoExpand: (direction) => {
    if (!direction) {
      organizer.autoExpandList = []
      organizer.lastChildren = {}
    } else {
      organizer.autoExpandList = organizer.autoExpandList.filter(i => i.direction !== direction)
      organizer.lastChildren[direction] = undefined
    }
  },
  handleResultsForTree: (result, direction) => {
    let [autoExpand, lastChild] = predictive.getAutoExpanded()
    organizer.autoExpandList = organizer.autoExpandList.concat(autoExpand.map(id=>({id, direction})))
    organizer.lastChildren[direction] = lastChild || BASIC_ITEM
  },
  parseResults: (result, terms) => {
    if (result.griLevel === 'chapter')
      return manual.search(result, {...terms, description: result.originalDescription})
    else
      return predictive.organize(result)
  },
  parseV2Result: (result, terms) => {
    let ret = []

    if(result.griLevel != 'dutiable')
      ret =  organizer.parseV2NextLvlResult(result, terms)
    else
      ret = organizer.parseV2DutiableResult(result)

    return ret

  },parseV2NextLvlResult: (result, terms, ret) => {
    let res = []

    if(result.results == null) {
      res.push({remark: "No additional results found. Please consider trying with a different HS code"})
      return  [res, [], []];
    }

    result.results.forEach((r) => {
      organizer.v2SetID(r, null, null, null, terms)
      res.push(r)
    })

    return [res, [], []]

  },findNodeChildren: (result, hsCode) => {
    let children = {}

    if(result.children === null)
      return null

    if(result.hs_code === hsCode)
      return result
    else
      children = result.children.find(c => c.hs_code === hsCode)

    if(children === undefined)
      children = organizer.findNodeChildren(result.children[0], hsCode)

    return children

  },parseV2DutiableResult: (result, ret) => {
    let res = []
    let expandable = []
    let active = []

    if(result.results == null) {
      res.push({remark: "No additional results found. Please consider trying with a different HS code"})
      return [res, [], []];
    }

    if(result.results.length > 1) {
      result.results.forEach((r) => {
        organizer.v2SetID(r, null, null, null, null)
        res.push(r)
      })
    } else {
      let r = result.results.find(x=>x!==undefined)
      organizer.v2SetID(r, null, expandable, active)
      res.push(r)
    }

    return [res, expandable, active.find(x=>x!==undefined)]
  },v2SetID: (elem, id, ex, active, terms) => {
      elem.id = organizer.generateRandomNum() + ''

      if(id !== null)
        elem.parentId = id

      if(elem.type != 'dutiable' && elem.children === undefined) {
        elem.hasChildren = true
        elem.isManual = true
        elem.terms = terms
      }

      if(elem.children !== undefined) {
        elem.hasChildren = true
        elem.children.forEach(e => {
          organizer.v2SetID(e, elem.id, ex, active,terms)
        })
      }

      elem.hs_code = elem.hsCode
      elem.hs_description = elem.hsDescription

      if(elem.type == "dutiable" && active != null)
        active.push(elem)
      if(ex != null)
        ex.unshift(elem.id)

  },generateRandomNum: () => {
    const cryp = window.crypto || window.msCrypto;
    const tab= new Uint32Array(1);

    return cryp.getRandomValues(tab)[0] / 0x100000000;
  }

}
