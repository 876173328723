import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { currencyAdornment } from './components'
import { INCOTERMS_REQUIRED } from './form'

import { components } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter: {useField, useFormikContext}
    },
    field: {CngTextField}
  }
} = components

const FIELDS = {
  'Freight costs': [
    ['Origin country amount', 'freightInlandOrigin'],
    ['Destination country amount', 'freightInlandDestination'],
    ['International freight', 'freightInternational']
  ],
  'Other charges': [
    ['Origin country charges', 'originCharge', 'Any dutiable charges, such as warehouse charges, storage charges, special handling, etc. in the country of export'],
    ['Destination country charges', 'destinationCharge', 'Any charges that add up to the cost of goods, example, storage, special handling, etc.'],
    ['Insurance', 'insurance']
  ]
}

const getHelperText = (errors, hint) => {
  let ret = hint
  if (hint && errors)
    ret += ' - ' + errors
  return ret
}

function ChargesForm(props) {
  const formik = useFormikContext()
  const { formState: { errors }, watch, ...form } = useFormContext()
  const incoterm = watch('incoterm')
  const currencyCode = watch('currencyCode')

  return (<Box component={Grid} container direction='column' spacing={4} pb={5}>
    {Object.entries(FIELDS).map(([title, group]) => (
      <Grid item key={title}><Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' gutterBottom><b>
            {title} ({currencyCode || 'USD'})
          </b></Typography>
        </Grid>
        {group.map(([label, name, hint]) => (
          <Grid item xs={12} sm key={name}>
            <CngTextField
              label={label}
              name={name}
              size='small'
              required={INCOTERMS_REQUIRED[name].includes(incoterm)}
              InputProps={{
                startAdornment: currencyAdornment(form)
              }}
              helperText={getHelperText(errors[name], hint)}
            />
          </Grid>
        ))}
      </Grid></Grid>
    ))}
  </Box>)
}

export default ChargesForm
