import {
  makeStyles
} from '@material-ui/core'
import React, { useMemo, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TASContext } from 'src/store/intelligent-advisory/tas'
import { COLOR } from '../helper'

import CngTabBar from 'src/components/tabs'

const useStyles = makeStyles(theme => ({
  origin: {
    borderColor: COLOR.origin + ' !important'
  },
  destination: {
    borderColor: COLOR.destination + ' !important'
  }
}))

function TabBar(props) {
  const classes = useStyles()
  const {
    activeDirection,
    setDirection,
    isOriginLoading, isDestinationLoading, requestType
  } = useContext(TASContext)

  const activeTab = useMemo(() =>
    activeDirection === 'origin' ? 0 : 1
  , [activeDirection])

  const handleTabChange = i => {
    if(requestType === 'import' && i === 0)
      setDirection('destination')
    else if(requestType === 'export' && i === 0)
      setDirection('origin')
    else {
      let dir = i === 0 ? 'origin' : 'destination'
      setDirection(dir)
    }
  }

  function getTab() {
    let tabList = []
    if(requestType == "both" || requestType == "export") {
      let tab = {
        label: `Origin - ${props.origin || ''}`,
        selectedClass: classes.origin,
        disabled: isOriginLoading,
        icon: isOriginLoading ? <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin /> : null
      }
      tabList.push(tab)
    }

    if(requestType == "both" || requestType == "import") {
      let tab = {
        label: `Destination - ${props.destination || ''}`,
        selectedClass: classes.destination,
        disabled: isDestinationLoading,
        icon: isDestinationLoading ? <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin /> : null
      }
      tabList.push(tab)
    }

    return tabList
  }

  return (<CngTabBar
    textColor='inherit'
    value={activeTab}
    tabs={getTab()}
    onActiveTabChanged={handleTabChange}
  />)
}

export default TabBar
