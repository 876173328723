import React, {useEffect, useState} from "react";
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    IconButton
} from "@material-ui/core";
import {findActive, findExpendedPath, formatResult, parseResult} from "../../utils"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditDialog from "./EditDialog";

import {DESTINATION, ORIGIN} from 'src/store/intelligent-advisory/tas'

import {TASContext} from "../../../../../store/intelligent-advisory";


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTableCell-head": {
            fontWeight: 'bolder'
        },
    },

}))

export default function ResultTable(props) {
    const classes = useStyles()
    const [rows, setRows] = useState([])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [open, setOpen] = React.useState(false);
    const [rowData, setRowData] = useState([])
    const [editData, setEditData] = useState({origin: [], destination: []})

    const context = React.useContext(TASContext)

    useEffect(() => {
        console.log(props.productList.requestType)
        if(props.productList.requestType.toLowerCase() == 'import') {
            context.setDirection(ORIGIN)
        } else if(props.productList.requestType.toLowerCase() == 'export') {
            context.setDirection(DESTINATION)
        } else {
            context.setDirection(ORIGIN)
        }
    },[rows])

    const handleEdit = (row) => {
        console.log(row)
        let hsTree = parseResult(row.hsResult)
        let result = {origin: [], destination: []}

        if(row.activeTree.import != null && row.activeTree.import != "") {
            let manualResultJson = JSON.parse(row.activeTree.import)
            result.origin.data = manualResultJson.data
            result.origin.active = manualResultJson.active
            result.origin.expanded = manualResultJson.expanded
        } else if(hsTree.import.length > 0) {
            hsTree.import.splice(1, hsTree.import.length-1)
            result.origin.data = hsTree.import;
            result.origin.active = findActive(hsTree.import[0], row.dutiableHs.import, [])
            result.origin.expanded = findExpendedPath(hsTree.import[0], row.dutiableHs.import, { "path" : [], "parentId" : ''}).path
        }

        if(row.activeTree.export != null && row.activeTree.export != "") {
            let manualResultJson = JSON.parse(row.activeTree.export)
            result.destination.data = manualResultJson.data
            result.destination.active = manualResultJson.active
            result.destination.expanded = manualResultJson.expanded
        } else if(hsTree.export.length > 0) {
            hsTree.export.splice(1, hsTree.export.length-1)
            result.destination.data = hsTree.export;
            result.destination.active = findActive(hsTree.export[0], row.dutiableHs.export, [])
            result.destination.expanded = findExpendedPath(hsTree.export[0], row.dutiableHs.export, { "path" : [], "parentId" : ''}).path
        }

        console.log(result)
        setRowData(row)
        setEditData(result)
        setOpen(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        let productRow = []
        let filteredData = []

        productRow = formatResult(props.productList)

        if(props.keyword != '' && props.keyword.length > 1) {
            filteredData = productRow.filter(row => row.prodDes.toLowerCase().includes(props.keyword.toLowerCase())
                || row.origin.toLowerCase().includes(props.keyword.toLowerCase())
                || row.des.toLowerCase().includes(props.keyword.toLowerCase())
                || row.dutiableHs.export.toLowerCase().includes(props.keyword.toLowerCase())
                || row.dutiableHs.import.toLowerCase().includes(props.keyword.toLowerCase())
                || row.remark.toLowerCase().includes(props.keyword.toLowerCase()))
        } else {
            filteredData = productRow
        }

        setRows(filteredData)
        props.setRowCount(filteredData.length)
        props.setResult(productRow)
    }, [props.keyword], rowData)

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow className={classes.root}>
                        <TableCell >#</TableCell>
                        <TableCell >LEVEL</TableCell>
                        <TableCell >PRODUCT DESCRIPTION</TableCell>
                        <TableCell >ORIGIN</TableCell>
                        <TableCell >DESTINATION</TableCell>
                        <TableCell >EXPORT HS CODE</TableCell>
                        <TableCell >IMPORT HS CODE</TableCell>
                        <TableCell >REMARKS</TableCell>
                        <TableCell ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row, i) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell >{(i + 1)+(page * 10)}</TableCell>
                            <TableCell >{row.level}</TableCell>
                            <TableCell >{row.prodDes}</TableCell>
                            <TableCell >{row.origin}</TableCell>
                            <TableCell >{row.des}</TableCell>
                            <TableCell >{row.dutiableHs.export}</TableCell>
                            <TableCell >{row.dutiableHs.import}</TableCell>
                            <TableCell >{row.remark}</TableCell>
                            <TableCell >
                                <IconButton onClick={() => handleEdit(row)} >
                                    <FontAwesomeIcon icon={['fal', 'pen-to-square']} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            onChangePage={handleChangePage}/>
                    </TableRow>
                </TableFooter>
            </Table>
            <EditDialog open={open} setOpen={setOpen} data={rowData} setData={setRowData} editData={editData} setEditData={setEditData} productList={props.productList}/>
        </TableContainer>
    );
}
