import {
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  Table, TableBody, TableCell, TableRow,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import clsx from 'clsx'

import { LandedCostContext } from 'src/store/intelligent-advisory'

import BasicDialog from 'src/components/dialog'

const useStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    background: theme.palette.common.white,
    borderColor: theme.palette.divider,
    '&.MuiChip-clickable': {
      borderRadius: 8,
      '&:hover': {
        background: 'transparent',
        borderColor: theme.palette.primary.main
      }
    }
  },
  flag: {
    width: '1.1em',
    height: '1.1em'
  },
  conversion: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  details: {
    display: 'table',
    fontSize: '.9em',
    marginTop: theme.spacing(1.5),
    '& td': {
      padding: theme.spacing(1.25, 2),
      '&:nth-child': {
        '&(1)': { borderRadius: '14px 0 0 14px' },
        '&(2)': { color: theme.palette.text.textSecondary },
        '&(3)': { borderRadius: '0 14px 14px 0' }
      }

    }
  },
  bg: {
    background: theme.palette.primary.main + '10',
    borderRadius: 14
  },
  formula: {
    marginTop: theme.spacing(.5),
    fontSize: '.9em',
    color: theme.palette.text.textSecondary
  },
  spacing: {
    margin: theme.spacing(1.5, -1, .5),
    padding: theme.spacing(.5, 1.5),
    fontWeight: 'bolder'
  },
  inner: { padding: theme.spacing(1, 1.25) }
}))

function TaxInfo(props) {
  const classes = useStyles()
  const { countryDetails } = useContext(LandedCostContext)

  const [formulaOpen, setFormulaOpen] = useState(false)

  const handleFormulaClose = () => setFormulaOpen(false)

  const getConvertedCost = () => {
    let rate = props.rate.conversionRate
    let code = props.rate.outputCurrency
    if (props.currencyCode === props.rate.outputCurrency) {
      rate = 1 / rate
      code = props.rate.inputCurrency
    }
    return `${Math.round((props.totalLandedCost * rate + Number.EPSILON) * 10000) / 10000} ${code}`
  }

  return (<Card variant='outlined'><Box p={1}>
    <Box component={Typography} variant='h5' px={.5}><b>{props.label}</b></Box>
    <Table size='small' className={classes.details}><TableBody>
      {props.dutyAndTaxDetail.map((dt) => <TableRow key={dt.description}>
        <TableCell>{dt.description}</TableCell>
        <TableCell>{dt.formula}</TableCell>
        <TableCell align='right'>{dt.value}&nbsp;{dt.currencyCode}</TableCell>
      </TableRow>)}
      <TableRow className={classes.bg}>
        <TableCell><b>Total landed cost</b></TableCell>
        <TableCell>
          {props.breakdown.map((bd, i) => <Box key={bd.description} display='inline'>
            {i > 0 && ' + '}{bd.description}
          </Box>)}&ensp;
          <Chip
            variant='outlined'
            size='small'
            className={classes.chip}
            label='See formula'
            onClick={() => setFormulaOpen(true)}
          />
        </TableCell>
        <TableCell align='right'><b>
          {props.totalLandedCost}&nbsp;{props.currencyCode}
        </b></TableCell>
      </TableRow>
    </TableBody></Table>

    <Grid container justify='flex-end'>
      <Card variant='outlined' className={classes.conversion}>
        <Grid container spacing={4} justify='flex-end' alignItems='center'>
          {countryDetails && <Grid item>
            <Grid container spacing={1} alignItems='center'>
              <Grid item><Avatar
                src={`/static/images/countries/${countryDetails.origin.code}.svg`}
                alt={`${countryDetails.origin.name} flag`}
                className={classes.flag}
              /></Grid>
              <Grid item>
                1 {props.rate.inputCurrency}
                &ensp;:&ensp;
                {props.rate.conversion}
                &nbsp;{props.rate.outputCurrency}
              </Grid>
              <Grid item><Avatar
                src={`/static/images/countries/${countryDetails.destination.code}.svg`}
                alt={`${countryDetails.destination.name} flag`}
                className={classes.flag}
              /></Grid>
            </Grid>
          </Grid>}
          <Box component={Grid} item color='primary.main'>
            {getConvertedCost()}
          </Box>
        </Grid>
      </Card>
    </Grid>

    <BasicDialog
      open={formulaOpen}
      titleDivider
      title='Total landed cost formula'
      onClose={handleFormulaClose}
      confirm='OK'
    >
      <Grid container
        direction='column'
        spacing={1}
        className={classes.formula}
      >
        {props.breakdown.map((bd, i) => <Grid item key={bd.description}><Grid container spacing={1}>
          <Grid item xs>{bd.description}</Grid>
          <Box component={Grid} item color='text.cngBlack'>{bd.value}</Box>
          <Grid item>
            {props.currencyCode}&nbsp;
            {i < props.breakdown.length - 1 ? ' + ' : ' = '}</Grid>
        </Grid></Grid>)}
        <Grid item className={
          clsx(classes.bg, classes.spacing)
        }>
          <Grid container spacing={1}
            className={classes.inner}
          >
            <Grid item xs>Total landed cost formula</Grid>
            <Box component={Grid} item color='text.cngBlack'>
              {props.totalLandedCost}
            </Box>
            <Grid item>
              {props.currencyCode}&emsp;</Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicDialog>
  </Box></Card>)
}

export default TaxInfo
