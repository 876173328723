import {

} from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { LandedCostContext } from 'src/store/intelligent-advisory/lcc'

import Calculator from './calculator'

function LandedCost(props) {

  const [pastCalcs, setPastCalcs] = useState([])
  const [savedCalcs, setSavedCalcs] = useState([])

  if (!savedCalcs.length && !pastCalcs.length)
    return <Calculator />

  return <>Not ready</>
}

export default LandedCost
export { default as Result } from './result'
