import {
  Grid,
  Typography,
  makeStyles, Collapse, Box, Card
} from '@material-ui/core'
import React, {useContext, useEffect} from 'react'

import HSInfo from './components/HSInfo'
import ErrorContent from '../components/ErrorContent'
import CngTreeview from '../../../../components/treeview'

import { formatText } from './helper'
import {TASContext} from "../../../../store/intelligent-advisory";

const useStyles = makeStyles(theme => ({
  eadDetail: {
    margin: theme.spacing(1, 0),
    '& .ead-title': {
      fontWeight: 'bold'
    },
    '& .ead-subtitle': {
      margin: theme.spacing(1.5, 4),
      color: 'rgba(28, 29, 33, 0.7)',
      fontWeight: 'bold',
      fontSize: '1em'
    },
    '& .ead-detail': {
      margin: theme.spacing(0, 4),
      fontSize: '.9em',
      lineHeight: 'normal'
    }
  },
  paddingAll: {
    padding: theme.spacing(1.25)
  }
}))


function Overview(props) {
  const classes = useStyles()

  const {
    requestType
  } = useContext(TASContext)

  return (<>
    {/* Header */}
    {(requestType == "both" || requestType == "export") &&
    <Box component={Card} my={3} >
      <HSInfo {...props} requestTypeInfo='export' />

      <Collapse in={props.expanded.export} timeout="auto" unmountOnExit>
        <Grid container spacing={2} direction='column' className={classes.paddingAll}>
          {(!props.exportFootNotes && !props.formalities
              && !props.procedures && !props.prohibited)
          && <Grid item><ErrorContent /></Grid>}

          {/* Foot notes */}
          {props.exportFootNotes && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Foot Notes</Typography>
                <CngTreeview data={props.exportFootNotes} />
              </Grid>
          )}

          {/* Formalities */}
          {(props.exportFormalities && props.exportFormalities.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Formalities</Typography>
                <CngTreeview data={props.exportFormalities} />
              </Grid>
          )}

          {/* Procedures */}
          {(props.procedures && props.procedures.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Procedures</Typography>
                <CngTreeview data={props.procedures} />
              </Grid>
          )}

          {/* Prohibited */}
          {(props.prohibited && props.prohibited.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Prohibited Items</Typography>
                <CngTreeview data={props.prohibited} />
              </Grid>
          )}

        </Grid>
      </Collapse>
    </Box>}{/* Card */}

    {(requestType == "both" || requestType == "import") &&
    <Box component={Card} my={3}>
      <HSInfo {...props} requestTypeInfo='import'/>

      <Collapse in={props.expanded.import} timeout="auto" unmountOnExit>
        <Grid container spacing={2} direction='column' className={classes.paddingAll} >
          {(!props.importFootNotes &&!props.formalities
              && !props.procedures && !props.prohibited)
          && <Grid item><ErrorContent /></Grid>}

          {/* Foot notes */}
          {props.importFootNotes && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Foot Notes</Typography>
                <CngTreeview data={props.importFootNotes} />
              </Grid>
          )}

          {/* Formalities */}
          {(props.importFormalities && props.importFormalities.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Formalities</Typography>
                <CngTreeview data={props.importFormalities} />
              </Grid>
          )}

          {/* Procedures */}
          {(props.procedures && props.procedures.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Procedures</Typography>
                <CngTreeview data={props.procedures} />
              </Grid>
          )}

          {/* Prohibited */}
          {(props.prohibited && props.prohibited.length > 0) && (
              <Grid item className={classes.eadDetail}>
                <Typography variant='h4' gutterBottom className='ead-title'>Prohibited Items</Typography>
                <CngTreeview data={props.prohibited} />
              </Grid>
          )}

        </Grid>
      </Collapse>
    </Box>}
  </>)
}

export default Overview
