import {
  Box,
  Chip,
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ErrorContent from '../components/ErrorContent'
import { formatText, formatColors } from './helper'

const useStyles = makeStyles(theme => ({
  box: {
    background: 'rgba(28, 29, 33, 0.04)',
    borderRadius: 8
  },
  notes: {
    color: theme.palette.text.textSecondary,
    fontSize: '.9em'
  },
  bold: { fontWeight: 'bold' },
  truncate: {
    display: 'inline-block',
    maxWidth: '60vh',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  numeric: {
    color: theme.palette.text.error,
    fontWeight: 'bold'
  }
}))

function Duties(props) {
  const classes = useStyles()

  const [seeMore, setSeeMore] = useState({})

  const seeClass = tax => {
    if (!tax.specific_duties_require_expansion)
      return null
    return !seeMore[tax.id] ? classes.truncate : ''
  }

  const handleSeeMore = id => setSeeMore({...seeMore, [id]: !seeMore[id]})

  if (!props.duties || !props.duties.length)
    return <ErrorContent />

  return (<Grid container direction='column' spacing={2}>
    {props.duties.map((duty, i) => (<Grid item key={i}>
      <Box p={2} className={classes.box}>
        <Typography variant='h3' display='block'
          gutterBottom className={classes.bold}
        >
          {duty.groupName === 'default' ? 'Default' : duty.groupName}
        </Typography>
        <Grid container direction='column' spacing={2}>
          {duty.tax.map((tax, i) => {
            return (<Grid item key={tax.id}>
              {tax.specific_duties_title}:&ensp;
              <Box display={'inline' +
                  ((!seeMore[tax.id] && tax.specific_duties_require_expansion) ? '-flex' : '')
                }
                mr={tax.specific_duties_require_expansion ? 1 : 0}
              >
                <span className={seeClass(tax)}
                  dangerouslySetInnerHTML={{
                    __html: formatColors(
                      tax.specific_duties_description,
                      classes.numeric,
                      true
                    )
                  }}
                />
              </Box>
              {tax.specific_duties_require_expansion && <Chip
                label={seeMore[tax.id] ? 'See less' : 'See more'}
                onClick={e=>handleSeeMore(tax.id)}
                size='small' variant='outlined'
                color='primary'
                icon={<FontAwesomeIcon icon={['fal',
                  seeMore[tax.id] ? 'minus' : 'plus'
                ]} fixedWidth style={{marginLeft: 4}} />}
              />}
              <br/>
              <i className={classes.notes}
                dangerouslySetInnerHTML={{
                  __html: formatText(tax.specific_duties_additional_note)
                }}
              />
            </Grid>)
          })}
        </Grid>
      </Box>
    </Grid>))}
  </Grid>)
}

export default Duties
