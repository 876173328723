import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,

  makeStyles
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'

import { CIAApiUrls } from 'src/apiUrls'
import { LandedCostContext, getPartyID, handleErrors } from 'src/store/intelligent-advisory'

import { CngHeader, useServices } from 'cng-web-lib'

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '.65rem',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  button: {
    padding: theme.spacing(),
    color: theme.palette.text.textSecondary,
    borderRadius: 10,
    background: theme.palette.background.paper
  },
  barHolder: {
    marginTop: theme.spacing(-1.5),
    paddingBottom: theme.spacing(1.5)
  },
  bar: {
    height: 6,
    backgroundColor: '#00000014',
    '& > .MuiLinearProgress-bar': {
      borderRadius: 3
    }
  },
  accordion: {
    zIndex: 1200,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: theme.spacing(2),
    },
    borderRadius: '10px !important',
    transition: theme.transitions.create(),
    transition: 'margin 0s',
    '&.Mui-expanded': {
      padding: theme.spacing(.5),
      margin: `${theme.spacing(-.5, -.5, 0)} !important`,
      [theme.breakpoints.up('md')]: {
        boxShadow: theme.shadows[1]
      }
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(),
      flexDirection: 'column',
      '& .MuiList-root': {
        padding: 0,
        fontSize: '.9em'
      },
      '& .MuiListItem': {
        '&-root': {
          paddingLeft: 0,
          color: theme.palette.text.textSecondary
        },
        '&SecondaryAction-root': {
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          right: 0
        }
      }
    },
    '& .MuiAccordionSummary': {
      '&-root': {
        padding: 0,
        minWidth: 280,
        '&.Mui-expanded': {
          minHeight: 'unset'
        }
      },
      '&-content': {
        '&, &.Mui-expanded': {
          margin: 0,
          minHeight: 'unset'
        }
      }
    }
  }
}))

const UNLIMITED = 'No Limit'
const ALLTIME = 'Total Transaction'
const MONTHLY = 'Monthly Transaction'

const LCC = 'Landed Cost Calculation'
const HSSEARCH = 'HS Smart Search'
const EADVISORY = 'Compliance eAdvisory'

const TRANSACTION_TYPE = {
  [UNLIMITED]: 'Transactions',
  [ALLTIME]: 'Total Transactions',
  [MONTHLY]: 'Monthly Transactions'
}

function collateTransaction(res, isMounted, location) {
  let result = res.data.result
  let hsCodeTransType = result.TasHsCodeTransactionLimitType
  let complianceTransType = result.TasComplianceTransactionLimitType
  let landedCostTransType = result.TasLandedCostTransactionLimitType
  let collated = {
    'Landed Cost Calculation': [
      (landedCostTransType === MONTHLY ?
              result.TasLandedCostTransUsage
              : result.TotalTasLandedCostTransUsage
      ),
      landedCostTransType !== UNLIMITED &&
      result.TasLandedCostLimitVal
    ],
    'HS Smart Search': [
      (hsCodeTransType === MONTHLY ?
              (
                  result.TasGRISearchTransUsage + result.TasGRISearchHScodeTransUsage + //Current Month GRI
                  result.TasGRISearchOutOfCtryRestrictTransUsage + result.TasGRISearchHSCodeOutOfCtryRestrictTransUsage + //Current Month GRI
                  result.TasHsRecomendTransUsage + result.TasHsRecomendOutOfCtryRestrictTransUsage //Current Month HS recomend
              )
              : (
                  result.TotalTasGRISearchTransUsage + result.TotalTasGRISearchHSCodeTransUsage +  // TOTAL GRI
                  result.TotalTasGRISearchOutOfCtryRestrictTransUsage + result.TotalTasGRISearchHSCodeOutOfCtryRestrictTransUsage + //TOTAL GRI
                  result.TotalTasHsRecomendTransUsage + result.TotalTasHsRecomendOutOfCtryRestrictTransUsage //HS recomend
              )
      ),
      hsCodeTransType !== UNLIMITED &&
      result.TasHSClassificationLimitVal
    ],
    'Compliance eAdvisory': [
      (complianceTransType === MONTHLY ?
              (result.TasComplianceDetailTransUsage + result.TasComplianceDetailOutOfCtryRestrictTransUsage)
              : (result.TotalTasComplianceDetailTransUsage + result.TotalTasComplianceDetailOutOfCtryRestrictTransUsage)
      ),
      complianceTransType !== UNLIMITED &&
      result.TasComplianceDetailLimitVal
    ]
  }

  let pathName = location.pathname.split('/').pop()

  if(pathName === 'wizard') {
    collated.sum = collated["HS Smart Search"]
    collated.sum1 = collated["Compliance eAdvisory"]
    collated["HS Smart Search"] = null
    collated["Compliance eAdvisory"] = null
  } else if((pathName === 'calculator' || pathName === 'result')) {
    collated.sum = collated["Landed Cost Calculation"]
    collated["Landed Cost Calculation"] = null
  }

  return collated;
}

function getLimitLabel(key, transactionTypes, path, limit){
  if((path === 'calculator' || path === 'result') && key === HSSEARCH){
    return (transactionTypes.hsCodeTransType !== UNLIMITED? `/${limit}` : '/∞')
  } else if ((path === 'calculator' || path === 'result') && key === EADVISORY) {
    return (transactionTypes.complianceTransType !== UNLIMITED? `/${limit}` : '/∞')
  } else if ((path === 'wizard') && key === LCC) {
    return (transactionTypes.landedCostTransType !== UNLIMITED? `/${limit}` : '/∞')
  } else {
    return null
  }
}

function listServiceCount(data, transactionTypes, path) { 
  return <>
    {Object.entries(data).filter(([key, val]) => key !== 'sum' && key !== 'sum1' && val != null).map(([key, [usage, limit]]) => (
      <ListItem key={key}>
        <ListItemText>{key}</ListItemText>
        <ListItemSecondaryAction>
          {usage}
          {getLimitLabel(key, transactionTypes, path, limit)}
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </>
}

function transactionBoxCount (classes, transactionTypes, path, data) {
  return <>
    {(path === 'wizard') &&
      <>
        <Grid item xs>
          {HSSEARCH}
        </Grid>
        <Box component={Grid} item color='primary.main'><b>
          {data.sum[0]}
          {(transactionTypes.hsCodeTransType != UNLIMITED? `/${data.sum[1]}` : '/∞')}
        </b></Box>
        <Grid item xs={12} className={classes.barHolder}>
          <LinearProgress
              value={(data.sum[0]/data.sum[1]) * 100}
              className={classes.bar}
              variant='determinate'
          />
        </Grid>
      </>
    }

    {(path === 'calculator' || path === 'result') && 
      <>
        <Grid item xs>
          {LCC}
        </Grid>
        <Box component={Grid} item color='primary.main'><b>
          {data.sum[0]}
          {(transactionTypes.landedCostTransType != UNLIMITED? `/${data.sum[1]}` : '/∞')}
        </b></Box>
        <Grid item xs={12} className={classes.barHolder}>
          <LinearProgress
              value={(data.sum[0]/data.sum[1]) * 100}
              className={classes.bar}
              variant='determinate'
          />
        </Grid>
      </>
    }

    {(path == 'wizard') && <>
      <Grid item xs>
        Compliance eAdvisory
      </Grid>

      <Box component={Grid} item color='primary.main'><b>
        {data.sum1[0]}
        {transactionTypes.complianceTransType != UNLIMITED ? `/${data.sum1[1]}` : '/∞'}
      </b></Box>
      <Grid item xs={12} className={classes.barHolder}>
        <LinearProgress
            value={(data.sum1[0]/data.sum1[1]) * 100}
            className={classes.bar}
            variant='determinate'
        />
      </Grid>
    </>}
  </>
}

function CIAHeader({crumbs, title, ...props}) {
  const classes = useStyles()
  const { transactionCount } = React.useContext(LandedCostContext)
  const { enqueueSnackbar } = useSnackbar()
  const { securedSendRequest } = useServices()

  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const [transactionTypes, setTransactionTypes] = useState({
    "hsCodeTransType": UNLIMITED,
    "complianceTransType": UNLIMITED,
    "landedCostTransType": UNLIMITED
  })

  const location = useLocation()

  const [path, setPath] = useState(null)

  const [data, setData] = useState({
    sum: [0, 0],
    sum1: [0, 0]
  })

  const handleTransactions =  () => {
    let isMounted = true
    setLoading(true)
    securedSendRequest.execute(
      'post', CIAApiUrls.TRANSACTION_LIMIT,
      { party_id: getPartyID() },
      res => {
        let collated = collateTransaction(res, isMounted, location)
        let pathName = location.pathname.split('/').pop()
        let hsCodeTransType = res.data.result.TasHsCodeTransactionLimitType
        let complianceTransType = res.data.result.TasComplianceTransactionLimitType
        let landedCostTransType = res.data.result.TasLandedCostTransactionLimitType
        if (isMounted) {
          setPath(pathName)
          setData(collated)
          setTransactionTypes({
            "hsCodeTransType": hsCodeTransType,
            "complianceTransType": complianceTransType,
            "landedCostTransType": landedCostTransType
          })
        }
      },
      err => {
        setTransactionTypes({
          "hsCodeTransType": err.toString(),
          "complianceTransType": err.toString(),
          "landedCostTransType": err.toString()
        })
        handleErrors(enqueueSnackbar, err)
      },
      () => isMounted && setLoading(false)
    )
    return () => isMounted = false
  }

  useEffect(() => handleTransactions(), [transactionCount])

  const renderButton = () => {
    if (isLoading)
      return <>
        <Grid item xs>Retrieving transaction data...</Grid>
        <Box component={Grid} item color='primary.main'>
          <FontAwesomeIcon icon={['fal', 'spinner-third']}
            fixedWidth spin />
        </Box>
      </>

    if (!Object.keys(TRANSACTION_TYPE).includes(transactionTypes.hsCodeTransType))
      return <>
        <Grid item xs>{transactionTypes.hsCodeTransType}</Grid>
        <Box component={Grid} item color='text.error'>
          <FontAwesomeIcon icon={['fal', 'exclamation-triangle']}
            fixedWidth />
        </Box>
      </>

    return transactionBoxCount (classes, transactionTypes, path, data)
  }

  return (<Grid container spacing={3} justifyContent='space-between'>
    <Grid item xs>
      <CngHeader
        moduleTitle={title}
        breadcrumbsConfigurations={crumbs}
      />
    </Grid>
    {!location.pathname.includes("massrecommend") && <Grid item style={{position: 'relative'}} xs={12} md='auto'>
      <Accordion expanded={open} className={classes.accordion}>
        <AccordionSummary
          onMouseEnter={() => !isLoading && setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Button color='primary' className={classes.button} fullWidth>
            <Grid container spacing={2}
              justify='space-between'
              alignItems='center'
              style={{textAlign: 'left'}}
            >
              {renderButton()}
            </Grid>
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Box color='primary.main' className={classes.heading}>
            {'OTHER SERVICES'}
          </Box>
          <List dense>
            {listServiceCount(data, transactionTypes, path)}
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid> }
  </Grid>)
}

export default CIAHeader
