import {
    Paper,
    Box,
    Card,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Grid,
    makeStyles, Button, TextField, IconButton

} from '@material-ui/core'

import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";

import RequestTypeDropdown from "./components/RequestTypeDropdown";
import pathMap from "../../../../paths/PathMap_CalistaAdvisory";
import { useHistory, useLocation } from "react-router-dom";
import { useServices } from 'cng-web-lib'
import {CIAApiUrls} from "../../../../apiUrls";
import {useSnackbar} from "notistack";
import ListActionDropdown from "./components/ListActionDropdown";
import CircularProgressWithLabel from "./components/CircularProgressWithLabel";
import SortDropdown from "./components/SortDropdown";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(3),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        color: '#8996AF'
    },
    title: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    exportBtn: {
        margin: theme.spacing(.5),
    },
    tabs: {
        margin: theme.spacing(1, 0)
    },
    listItemAvatar: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        cursor:'pointer'
    },
    listItemEnd: {
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center'
    },
    bottomBtn: {
        padding: theme.spacing(1.25),
        fontSize: '1.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonTransform: {
        textTransform: 'none'
    },
}))

const PUBLIC_URL = process.env.PUBLIC_URL

function Home(props) {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const { securedSendRequest } = useServices()
    const { enqueueSnackbar } = useSnackbar()

    const [productList, setProductList] = useState([])
    const [fetchCompleted, setFetchCompleted] = useState(false)
    const [subsetProductList, setSubsetProductList] = useState([])
    const [loadIndex, setLoadIndex] = useState(5)
    const [disableLoadMore, setDisableLoadMore] = useState(false)
    const [hideIcon, setHideIcon] = useState(false)
    const [sortOrder, setSortOrder] = useState(0)
    const [prevSortIndex, setPrevSortIndex] = useState(0)
    const [resultCount, setResultCount] = useState(0)

    const handleClickListItem = (id) => {
        let productItem = productList.find((item) => item.uuid === id)

        if(productItem.recommendStatus != "Completed") {
            enqueueSnackbar('Result not yet ready.', { variant: 'error' })
            return
        }

        history.push(pathMap.CALISTA_ADVISORY_MASSRECOMMEND_RESULT + '/' + id, {productList: productItem});
    }

    const getFontColor = (status) => {
        if(status == "Completed")
            return "#56CB8A"
        else if(status == "Analysing")
            return "#5E81F4"

        return "#000000"
    }

    useEffect(() => {
        fetchMassRecommend()
        console.log(productList)
        setResultCount(productList.length)
        sortData(sortOrder)
        setSubsetProductList(productList.slice(0, loadIndex))
        console.log(location.pathname)
    }, [loadIndex, fetchCompleted, sortOrder, location.key])

    function fetchMassRecommend() {

        let url = CIAApiUrls.MASS_RECOMMEND_FETCH_PARTY
        let data = {}
        securedSendRequest.execute("GET", url, data,
            (response) => {
                setProductList(response.data)
                console.log(response)
            },
            (error) => {
                console.error(error)
            },
            () => {  setFetchCompleted(true) }
        )
    }

    function getCompleteStatus(list) {
        let completeCount = 0;

        list.map((item, i) => {
            if(item.status == "Completed")
                completeCount++
        })

        console.log(completeCount)
        console.log(list.length)
        console.log(completeCount/list.length)

        return (Math.round((completeCount/list.length) * 100))
    }

    function handleLoadIndex() {
        setLoadIndex(loadIndex + 5)
        if(loadIndex > productList.length)
            setLoadIndex(productList.length)
    }

    function handleSearchLayout(openSearchText) {
        let filteredData = []
        setHideIcon(openSearchText)

        if(!openSearchText) {
            filteredData = productList.slice(0, 5)
            setLoadIndex(5)
            setDisableLoadMore(false)
            setSubsetProductList(filteredData)
        }
    }

    function sortData(index) {

        if(prevSortIndex != index) {
            setLoadIndex(5)
            setPrevSortIndex(index)
        }

        productList.map((item, i) => {
            if(item.productName == null)
                item.productName = "Product Name"
        })

        if(index == 0) {
            productList.sort((a, b) => Date.parse(b.requestDate) - Date.parse(a.requestDate))
        } else if(index == 1) {
            productList.sort((a, b) => Date.parse(a.requestDate) - Date.parse(b.requestDate))
        } else if(index == 2) {
            productList.sort((a, b) => a.productName.localeCompare(b.productName))
        } else if(index == 3) {
            productList.sort((a, b) => b.productName.localeCompare(a.productName))
        }

    }

    let filter = (e) => {
        let keyword = e.target.value
        let filteredData = []

        if (keyword !== '' && keyword.length > 1) {
            filteredData = productList.filter(prod => prod.productName != null)
                .filter(nonNullProd => nonNullProd.productName.toLowerCase().includes(keyword.toLowerCase()))
            setResultCount(filteredData.length)
            setDisableLoadMore(true)
        } else {
            setResultCount(productList.length)
            filteredData = productList.slice(0, 5)
            setLoadIndex(5)
            setDisableLoadMore(false)
        }

        setSubsetProductList(filteredData)
    }

    return (
        <Paper>
            <Grid container direction="column" wrap="nowrap">
                <Box className={classes.header}>
                    <Box className={classes.title}>
                        {resultCount} Results
                    </Box>
                    {!hideIcon && <Box mr={2}>
                            <IconButton onClick={() => handleSearchLayout(true)}  >
                                <FontAwesomeIcon icon={['fal', 'search']} size="xs" />
                            </IconButton>
                        </Box> }

                    {!hideIcon &&
                        <Box mr={2}>
                            <SortDropdown setSortOrder={setSortOrder}/>
                        </Box> }
                    {!hideIcon && <RequestTypeDropdown /> }

                    {hideIcon &&
                        <TextField variant="outlined" label="Search" onChange={filter} placeholder="Name" /> }

                    {hideIcon &&
                        <IconButton onClick={() => handleSearchLayout(false)} >
                            <FontAwesomeIcon icon={['fal', 'times']} size="xs" />
                        </IconButton> }
                </Box>

                {subsetProductList.map((item, i) => {
                    return (<Box component={Card} mx={1.5} mb={3} px={3} >
                        <List sx={{ bgcolor: 'background.paper' }}>
                            <Grid container spacing={1} alignItems="center">
                                <ListItem>
                                    <Grid item xs={8} className={classes.listItemAvatar} onClick={() => handleClickListItem(item.uuid)}>
                                        <ListItemAvatar>
                                            <FontAwesomeIcon icon={['fal', 'file-alt']} />
                                        </ListItemAvatar>
                                        <ListItemText primary={item.productName != null ? item.productName : "Product Name"} secondary={item.productLists.length + " entries - " + item.requestType} />

                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText disableTypography
                                                      primary={<Typography variant="body2" color="textSecondary">Last Modified</Typography>}
                                                      secondary={<Typography variant="body1" color="textPrimary">{item.requestDate}</Typography>} />
                                    </Grid>
                                    <Grid item xs={1} >
                                        <ListItemText disableTypography
                                                      primary={<Typography variant="body2" color="textSecondary">Status</Typography>}
                                                      secondary={<Typography variant="body1"><span style={{fontWeight: 'bold', color: getFontColor(item.recommendStatus)}}>{item.recommendStatus}</span></Typography>} />
                                    </Grid>
                                    <Grid item xs={1} className={classes.listItemEnd}>
                                        {item.recommendStatus != "Completed" ? <CircularProgressWithLabel value={getCompleteStatus(item.productLists)} /> : <ListActionDropdown uuid={item.uuid} productList={productList}/>}
                                    </Grid>
                                </ListItem>
                            </Grid>
                        </List>
                    </Box>)
                })
                }
                {((loadIndex < productList.length && productList.length > 5) && !disableLoadMore) &&
                <Box className={classes.bottomBtn}>
                    <Button
                        id="basic-button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => handleLoadIndex()}
                        className={classes.buttonTransform}
                    >
                        Load more..
                    </Button>
                </Box>}
            </Grid>
        </Paper>
    )
}

export default Home
