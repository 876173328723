import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  Grid, ListItemIcon, makeStyles, Menu, MenuItem, Typography
} from '@material-ui/core'
import React, { useContext, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_CalistaAdvisory'

import { LandedCostContext } from 'src/store/intelligent-advisory/lcc'

import BasicDialog from 'src/components/dialog'

import { Retrieving, InfoCard, TaxInfo } from './components'

import { components, useServices } from 'cng-web-lib'
import { CIAApiUrls } from "../../../../apiUrls";
import { FileText, LogOut } from "react-feather";
import clsx from "clsx";
const {
  button: { CngPrimaryButton }
} = components

const toolbarStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiMenu': {
      '&-paper': { borderRadius: 10 },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.9em',
          padding: '8px 10px',
          minWidth: '12em',
          '&:hover': {
            backgroundColor:
              theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
        theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  }
}))

function Result(props) {
  const {
    displayResult, setFormValuesMemory,
    formValuesMemory, setEditCriteria
  } = useContext(LandedCostContext)
  const history = useHistory()
  const toolbarClasses = toolbarStyles()

  const [cfmDialogOpen, setCfmDialogOpen] = React.useState(false)

  const result = useMemo(() => (displayResult || {}), [displayResult])

  const handleNewCalc = () => {
    // clear form values
    setFormValuesMemory(null)
    return history.push(pathMap.CALISTA_ADVISORY_LCC)
  }

  const handleEditCriteria = () => {
    // clear form values
    setFormValuesMemory(formValuesMemory)
    setEditCriteria(true)
    return history.push(pathMap.CALISTA_ADVISORY_LCC)
  }

  const { securedSendRequest } = useServices()

  function generateLCCReport(fileType) {
    let urlLink = "";
    let fileExtension = "";

    if (fileType === "pdf") {
      urlLink = CIAApiUrls.LCC_EXPORT_PDF
      fileExtension = "pdf";
    } else if (fileType === "excel") {
      urlLink = CIAApiUrls.LCC_EXPORT_EXCEL;
      fileExtension = "xls";
    }

    securedSendRequest.execute(
      "POST",
      urlLink,
      displayResult,
      (exportData) => {
        console.log("success")
        console.log(exportData);
        let blob = exportData.data
        let timeStamp = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        let fileName = "Landed_Cost_Result_[" + displayResult.request.importHSCode + "]_[" + timeStamp + "]." + fileExtension;

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);

        //console.log(link)
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setAnchorEl(null)
      },
      (error) => {
        console.log(error)
      },
      onComplete,
      { responseType: "blob" }

    )
  }

  function onComplete() {
    console.log("complete")
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }


  return (<Grid container direction='column' spacing={2}>
    {displayResult && <Grid item style={{ width: '100%' }}>
      <Card><Box p={1}><InfoCard /></Box></Card>
    </Grid>}

    <Grid item><Card>
      {(result.dutyAndTaxBreakdown && result.landedCost && result.exchangeRateDetail) ? <>
        <Box p={1}>
          <Grid container spacing={2} direction='column'>
            {result.dutyAndTaxBreakdown.map((bd, i) => <Grid item key={i}>
              <TaxInfo
                {...bd}
                {...result.landedCost[i]}
                rate={result.exchangeRateDetail}
              />
            </Grid>)}
          </Grid>
        </Box>

        <Divider />
        <CardActions><Grid container spacing={1}>
          <Grid item xs>
            <Button
              color='primary'
              onClick={() => setCfmDialogOpen(true)}
              startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} fixedWidth />}
            >
              Edit criteria
            </Button>
          </Grid>
          <Grid item>
            <CngPrimaryButton
              startIcon={<LogOut size='16px' />}
              onClick={handleClick}
            >
              Export
            </CngPrimaryButton>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              className={clsx(toolbarClasses.menu)}
            >
              <MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
                {'Export as'}
              </MenuItem>
              <MenuItem onClick={() => generateLCCReport("pdf")}>
                <ListItemIcon>
                  <FileText size='16px' />
                </ListItemIcon>
                <Typography variant='inherit' style={{ fontSize: 'small' }}>
                  PDF
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => generateLCCReport("excel")}>
                <ListItemIcon>
                  <FileText size='16px' />
                </ListItemIcon>
                <Typography variant='inherit' style={{ fontSize: 'small' }}>
                  EXCEL
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <CngPrimaryButton
              startIcon={<FontAwesomeIcon icon={['fal', 'calculator-alt']} fixedWidth />}
              onClick={handleNewCalc}
            >
              New calculation
            </CngPrimaryButton>
          </Grid>
        </Grid></CardActions>
      </> : <Retrieving onBack={() => history.goBack()} />}
    </Card></Grid>
    <BasicDialog
      title='Go back to previous page'
      maxWidth='xs'
      open={cfmDialogOpen}
      cancel='Stay here'
      onClose={() => setCfmDialogOpen(false)}
      confirm='Go back'
      onConfirm={handleEditCriteria}
    >
      <Typography gutterBottom>
        Going back, then re-submitting&nbsp;
        <Typography color='error' component='b'>
          will count as a new transaction
        </Typography>.
        <br />
        <b>Are you sure you wish to go back?</b>
        <br />
      </Typography>
    </BasicDialog>
  </Grid>)
}

export default Result
