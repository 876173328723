import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import { SEARCH_TYPES } from '../search'
import LanguageDialog from './LanguageDialog'
import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'
import { getPartyID } from 'src/store/intelligent-advisory'

import { components, useServices } from 'cng-web-lib'
import {useFormContext} from "react-hook-form";
const {
  form: {
    field: {
      CngTextField,
      CngSelectField,
      CngRadioGroupField
    }
  },
  button: { CngPrimaryButton }
} = components

const COUNTRY_LOOKUP_URL_ORIGIN = CIAApiUrls.ORGN_CTRY;

const useStyles = makeStyles(theme => ({
  hideHints: {
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none'
    }
  }
}))

function Searchbar(props) {
  const _isMounted = React.useRef(true)
  const classes = useStyles()

  const form = useFormContext()

  const [openLanguageDialog, setOpenLanguageDialog] = useState(false)
  const [languageOpts, setLanguageOpts] = useState(null)
  const [languageIpts, setLanguageIpts] = useState(null)
  const [requestType, setRequestType] = useState('both')
  const { fetchRecords } = useServices()

  const requestTypeOption = [
    { label: "Import & Export", value: 'both' },
    { label: "Import", value: 'import' },
    { label: "Export", value: 'export' }
  ]

  useEffect(() => {
    getLanguage()
    return () => _isMounted.current = false
  }, [])

  const getLanguage = () => {
    axios(`${process.env.PUBLIC_URL}/static/tas/lang.json`).then(res => {
      if (_isMounted.current && res.data && res.data.result
        && res.data.result.lang && res.data.result.lang.length)
        setLanguageOpts(res.data.result.lang)
    })

    fetchRecords.execute(
      CIAApiUrls.GET_LANGUAGE,
      { customData: {} },
      data => setLanguageIpts(data.result.lang),
      error => console.log('ERROR', error)
    )
  }

  return (<Card variant='outlined'>
    <Box component={CardContent} mb={-1}>
      <Grid item xs={12}>
        <CngRadioGroupField
            name='requestType'
            options={requestTypeOption}
            direction='row'
            defaultValue='both'
            value=''
            onChange={(e) => {
              setRequestType(e.target.value)
              form.reset({
                type: 'auto',
                description: '',
                origin: '',
                destination: '',
                inputLanguage: 'en',
                outputLanguage: 'en',
                requestType: e.target.value })
              console.log(form.getValues())
            }}
        />
      </Grid>
      <Grid container
        wrap='nowrap'
        spacing={1}
        justify='space-between'

        alignItems='center'
        className={classes.hideHints}
      >

        <Grid item xs={2}>
          <CngSelectField
            label='Type'
            name='type'
            items={SEARCH_TYPES}
          />
        </Grid>
        <Grid item xs={true}>
          <CngTextField
            label='HS code or description'
            name='description'
          />
        </Grid>
        <Grid item>
          <Tooltip title='Language options'>
            <Box component={IconButton} className='cng-cia'
                 size='small' mx={.5}
                 onClick={() => setOpenLanguageDialog(true)}
            >
              <FontAwesomeIcon icon={['fal', 'language']} />
            </Box>
          </Tooltip>
        </Grid>
        {(requestType == "both" || requestType == "export") &&
        <Grid item xs={2}>
          <CountryAutocompleteField
            label='Origin'
            name='origin'
            accessor='countryList'
            url={COUNTRY_LOOKUP_URL_ORIGIN}
            lookupProps={{customData: {
              party_id: getPartyID()
            }}}
          />
        </Grid>}
        {(requestType == "both" || requestType == "import") &&
        <Grid item xs={2}>
          <CountryAutocompleteField
            label='Destination'
            name='destination'
            accessor='countryList'
            url={COUNTRY_LOOKUP_URL_ORIGIN}
            lookupProps={{customData: {
              party_id: getPartyID()
            }}}
          />
        </Grid>}
        <Grid item>
          <CngPrimaryButton
            size='large'
            startIcon={<FontAwesomeIcon icon={['fal', 'search']} fixedWidth size='sm' />}
            type='submit'
          >
            Search
          </CngPrimaryButton>
        </Grid>
      </Grid>
    </Box>
    {props.isLoading && <LinearProgress />}
    <LanguageDialog
      open={openLanguageDialog}
      onClose={() => setOpenLanguageDialog(false)}
      langOu={languageOpts || []}
      langIn={languageIpts || []}
    />
  </Card>)
}

export default Searchbar
