import {
    Box,
    DialogContent,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { components } from 'cng-web-lib'
import CIAApiUrls from "../../../../apiUrls/CIAApiUrls";

const {
    form: {
       field: { CngLookupAutocompleteField }
    },
    CngDialog,
    button: { CngPrimaryButton }
} = components


function CountryDialog(props) {

    return (<CngDialog
        open={props.open}
        fullWidth
        maxWidth='xs'
        dialogTitle={<Grid container wrap='nowrap' justify='space-between'>
            <Typography variant='h5'><b>Country Pair</b></Typography>
            <Tooltip title='Close'>
                <IconButton className='cng-cia' size='small'
                            onClick={props.onClose}
                >
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </IconButton>
            </Tooltip>
        </Grid>}
        customDialogContent={<DialogContent>
            <Typography gutterBottom variant='body2'>Import Country</Typography>
            <Box mt={1} mb={3}>
                <CngLookupAutocompleteField
                    name='toCountry'
                    label='Country'
                    defaultValue={props.eAdvisoryCountry.toCountryLabel != null && props.eAdvisoryCountry.toCountryLabel}
                    lookupProps={{
                        url: CIAApiUrls.ORGN_CTRY,
                        dataAccessor: 'countryList',
                        label: 'name',
                        value: 'code',
                    }}
                    disabled={props.requestType === 'import'}
                    onChange={(e, val) => {
                        props.setEAdvisoryCountry({...props.eAdvisoryCountry, toCountryCode: val.value})
                    }}
                />
            </Box>
            <Typography gutterBottom variant='body2'>Export Country</Typography>
            <Box mt={1} mb={3}>
                <CngLookupAutocompleteField
                    name='fromCountry'
                    label='Country'
                    defaultValue={props.eAdvisoryCountry.fromCountryLabel != null && props.eAdvisoryCountry.fromCountryLabel}
                    lookupProps={{
                        url: CIAApiUrls.ORGN_CTRY,
                        dataAccessor: 'countryList',
                        label: 'name',
                        value: 'code'
                    }}
                    disabled={props.requestType === 'export'}
                    onChange={(e, val) => {
                        props.setEAdvisoryCountry({...props.eAdvisoryCountry, fromCountryCode: val.value})
                    }}
                />
            </Box>
            <Box mt={1} mb={1}>
                <CngPrimaryButton type='submit' color='primary' onClick={props.onClickHandle} fullWidth>
                    Apply
                </CngPrimaryButton>
            </Box>
        </DialogContent>}
    />)
}

export default CountryDialog
