import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

import { getHSCode } from 'src/store/intelligent-advisory'

import { components } from 'cng-web-lib'
const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField
    }
  },
  button: { CngPrimaryButton }
} = components

const useStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderColor: theme.palette.divider
  },
  card: {
    backgroundColor: '#F4BE5E10',
    borderColor: '#F4BE5E1F',
    '& .ng-form-control': {
      background: theme.palette.common.white + ' !important'
    }
  }
}))

function HSInfo(props) {
  const classes = useStyles()
  const formik = useFormikContext()

  return (<Box component={Card} my={1} p={1}
    className={classes.card}
  >
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <Chip label={getHSCode(props.hsCode).join(' ')}
          variant='outlined'
          size='small'
          className={classes.chip}
        />
      </Grid>
      <Grid item xs>
        {props.hsDescription}
      </Grid>
      {/* <Grid item>
        <IconButton className='cng-cia' onClick={props.onRemove}>
          <FontAwesomeIcon icon={['fal', 'times']}
            fixedWidth size='sm'
          />
        </IconButton>
      </Grid> */}
    </Grid>
    <Grid container spacing={1}>
      {props.uomList.map(([measureUnit, endAdornment], i) =>
        <Grid item key={i} xs>
          <CngTextField
            label={capitalize(measureUnit)}
            name={`measures[${i}].measureQuantity`}
            size='small'
            InputProps={{ endAdornment }}
            required
          />
        </Grid>
      )}
    </Grid>
  </Box>)
}

HSInfo.defaultProps = {
  uomList: []
}

HSInfo.propTypes = {
  hsCode: PropTypes.string.isRequired,
  hsDescription: PropTypes.string,
  uomList: PropTypes.arrayOf(PropTypes.array)
}

export default HSInfo
