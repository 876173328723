import {
  Box,
  Card as MuiCard,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { LandedCostContext, getUnit } from 'src/store/intelligent-advisory'

import BasicDialog from 'src/components/dialog'
import ItemInfo from './ItemInfo'
import FieldBox from './FieldBox'

const Card = props => <Grid item>
  <MuiCard variant='outlined'><Box p={1}>
    {props.title && <Typography variant='h5' gutterBottom><b>
      {props.title}
    </b></Typography>}
    {props.custom ? props.children : <Grid container spacing={2}>
      {props.children}
    </Grid>}
  </Box></MuiCard>
</Grid>

function DetailDialog(props) {
  const { displayResult: {
    request: { measures, ...request }
  }, countryDetails, formValuesMemory: {
    currencySymbol
  }} = useContext(LandedCostContext)

  return (<BasicDialog
    title='Landed cost calculation details'
    confirm='Close'
    maxWidth='md'
    ContentProps={{style: {padding: 0}}}
    {...props}
  ><Box p={2}>
    <Grid container spacing={2} direction='column' wrap='nowrap'>
      <Card custom>
        <ItemInfo><Grid item xs={12}><Grid container spacing={2}>
          {(measures || []).map((m, i) => (
            <Grid item xs={3} key={i}>
              <FieldBox
                label={m.measureUnit}
                value={m.measureQuantity}
                extra={getUnit(m.measureUnit) || m.measureCode}
              />
            </Grid>
          ))}
        </Grid></Grid></ItemInfo>
      </Card>

      <Card title='Incoterm'>
        <Grid item xs={3}>
          <FieldBox
            label='Incoterm'
            value={request.incoterm}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Incoterm currency'
            value={request.inputCurrencyCode}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Incoterm value'
            value={request.merchandiseValue}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
      </Card>

      <Card title='Shipment details'>
        <Grid item xs={3}>
          <FieldBox
            label='Mode of transportation'
            value={request.mot}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Shipment date'
            value={request.importDate}
          />
        </Grid>
      </Card>

      <Card title='Freight costs'>
        <Grid item xs={3}>
          <FieldBox
            label='Origin country amount'
            value={request.freightInlandOrigin}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Destination country amount'
            value={request.freightInlandDestination}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='International amount'
            value={request.freightInternational}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
      </Card>

      <Card title='Other charges'>
        <Grid item xs={3}>
          <FieldBox
            label='Origin country amount'
            value={request.originCharge}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Destination country amount'
            value={request.destinationCharge}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
        <Grid item xs={3}>
          <FieldBox
            label='Insurance amount'
            value={request.insurance}
            extra={currencySymbol || request.inputCurrencyCode}
          />
        </Grid>
      </Card>
    </Grid>
  </Box></BasicDialog>)
}

export default DetailDialog
