import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { TASContext, ORIGIN, DESTINATION, DUTIABLE } from 'src/store/intelligent-advisory/tas'
import { COLOR } from './helper'

import CngTreeview from 'src/components/treeview'
import { TabBar, CodeTable, HSBox } from './components'

const PUBLIC_URL = process.env.PUBLIC_URL

const useStyles = makeStyles(theme => ({
  dNone: { display: 'none' },
  lightBtn: {
    color: theme.palette.primary.main,
    backgroundColor: '#5E81F419',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    }
  },
  header: {
    fontWeight: 600,
    fontSize: '1.05em',
    lineHeight: 1.2,
    marginRight: theme.spacing()
  },
  treeCard: {
    boxShadow: 'none',
    marginTop: theme.spacing(2)
  },
  media: {
    height: 438,
    backgroundSize: 'contain',
    margin: theme.spacing(6, 2)
  }
}))

function SearchResult(props) {
  const classes = useStyles()
  const form = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  const {disable} = props;

  const {
    activeDirection, activeItem,
    setActiveFor, handleExpansion,
    expandedItems,
    activeTree, setTreeData,
    requestType,
    destinationTree: {
      active: destinationHS
    },
    originTree: {
      active: originHS
    },
    ...context
  } = React.useContext(TASContext)
  const eAdvisoryReady = useMemo(
    () => (activeDirection === DESTINATION
      && destinationHS.hs_code && destinationHS.type === DUTIABLE) || (activeDirection === ORIGIN && requestType == "export"
      && originHS.hs_code && originHS.type === DUTIABLE),
  [activeDirection, destinationHS, originHS])
  const hasResults = useMemo(() => {
    let activeInfo = props[activeDirection]
    return activeInfo && activeInfo.results
      && activeInfo.results.length > 0
  }, [activeDirection, props[activeDirection]])


  const treeHeaderRender = node => (<>
    <Typography className={classes.header}>{node.hs_description}</Typography>
    <HSBox code={node.hs_code} color={COLOR[activeDirection]} />
  </>)

  // Button handling
  const handleContinue = () => {
    if (eAdvisoryReady){
      return props.onEAdvisory()
    }
    if (activeDirection === ORIGIN && requestType == "both"){
      context.setDirection(DESTINATION)
    }else{
      enqueueSnackbar(`Please select a ${DUTIABLE} HS code for your ${DESTINATION} country.`, { variant: 'error' });
    }
  }

  const handleActiveItemChange = (node, isActive, isExpanded, openItems) => {
    console.log(node)
    setTreeData({
      ...activeTree,
      active: node,
      expanded: openItems
    })
    props.onHeaderClicked(node, isActive, isExpanded)
  }

  const handleManualSearch = () => {
    if (!form.getValues('description'))
      enqueueSnackbar('Please enter a description.', {variant: 'error'})
    else
      form.trigger().then(isValid =>
        isValid && props.onManualSearch(form.getValues())
      )
  }

  return (<>
    <CardContent>
      <Grid container direction='column' spacing={2}>
        {/* Tab Bar */}
        <Grid item>
          <TabBar
            origin={props.origin && props.origin.label}
            destination={props.destination && props.destination.label}
          />
        </Grid>

        {/* Code Table */}
        {hasResults && (
          <CodeTable
            location={props[activeDirection].label}
            color={COLOR[activeDirection]}
          />
        )}

        {/* Result */}
        <Card className={classes.treeCard}>
          {hasResults ? (
            <CngTreeview
              data={props[activeDirection].results}
              renderHeader={treeHeaderRender}
              onToggle={handleActiveItemChange}
              hideInactiveSiblings
              expanded={activeTree.expanded}
              active={activeItem.id}
              autoCascade
            />
          ) : <CardMedia
            className={classes.media}
            image={PUBLIC_URL + '/static/images/graphics/search.svg'}
          />}
        </Card>

        {/* Manual Search */}
        {(props.origin || props.destination) && (
          <Grid item container direction='column'
            justify='center' alignItems='center'
          >
            <Box mt={4} />
            <Typography variant='body1' gutterBottom>
              Not the results you are looking for?
            </Typography>
            <Button className={classes.lightBtn}
              variant='contained'
              onClick={handleManualSearch}
              startIcon={<FontAwesomeIcon icon={['fal', 'arrow-circle-right']} fixedWidth />}
            >
              Search manually
            </Button>
          </Grid>
        )}
      </Grid>
    </CardContent>
    <Box component={CardActions} justifyContent='center' my={2}>
      <Button disabled={disable} color='secondary' variant='contained'
        onClick={handleContinue}
        startIcon={<FontAwesomeIcon icon={['fal', 'chart-network']} fixedWidth />}
      >
        Continue
        {eAdvisoryReady ? ' with eAdvisory' : ''}
        {disable && <FontAwesomeIcon icon={['fal', 'spinner-third']} transform="right-10"  spin fixedWidth />}
      </Button>
    </Box>
  </>)
}

SearchResult.defaultProps = {
  onActiveTabChange: dir => dir,
  onHeaderClicked: node => node,
  onEAdvisory: e => e,
  origin: {},
  destination: {}
}

SearchResult.propTypes = {
  origin: PropTypes.shape({
    targetCountry: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object)
  }),
  destination: PropTypes.shape({
    targetCountry: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object)
  })
}

export default SearchResult
