import {makeStyles, Menu, MenuItem, IconButton, ListItemIcon, Typography} from '@material-ui/core'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { useServices } from 'cng-web-lib'
import React from 'react'
import clsx from "clsx"

import { formatResult } from "../../utils"
import {CIAApiUrls} from "../../../../../apiUrls";

const toolbarStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiMenu': {
            '&-paper': { borderRadius: 10 },
            '&-list': {
                maxWidth: '60vw',
                padding: 2,
                '& .MuiListItem-root:not(.Mui-disabled)': {
                    letterSpacing: 0.21,
                    fontSize: '.9em',
                    padding: '8px 10px',
                    minWidth: '12em',
                    '&:hover': {
                        backgroundColor:
                        theme.palette.component.popoverMenu.listItemHoverFill
                    }
                }
            }
        },
        '& .ng-mattbl-popover-checkbox': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .ng-mattbl-popover-title': {
            backgroundColor:
            theme.palette.component.textFormControl.dropdownMenuSelectedFill,
            borderRadius: 8,
            fontWeight: 900,
            color: theme.palette.text.navActive,
            padding: '10px 8px',
            marginBottom: theme.spacing(0.5)
        }
    }
}))

function ListActionDropdown(props) {
    const toolbarClasses = toolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { securedSendRequest } = useServices()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function generateReportExcel() {

        let item = props.productList.find((i) => i.uuid === props.uuid)
        let result = formatResult(item)

        let url = CIAApiUrls.MASS_RECOMMEND_GENERATE_REPORT_EXCEL;
        let data = new FormData();
        let resultTxt = JSON.stringify(result);

        console.log(resultTxt)

        data.append('result', resultTxt);
        securedSendRequest.execute("POST", url, data,
            (response) => {
                let blob = response.data
                let fileName = "mass_recommend_result_["+props.uuid+"].xlsx";

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);

                //console.log(link)
                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            },
            (error) => {
                console.error(error)
            },
            onComplete,
            {responseType: "blob"}
        )
    }

    function onComplete() {
        console.log("Complete")
    }

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ horizontal: "right" }}
                className={clsx(toolbarClasses.menu)}
            >
                <MenuItem onClick={ generateReportExcel }>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={['fas', 'arrow-to-bottom']} />
                    </ListItemIcon>
                    <Typography variant="inherit">Download Excel</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default ListActionDropdown