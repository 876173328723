import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'

import { Context as TASContext } from 'src/store/intelligent-advisory/tas'

import QuickSearch from './tas'
import LandedCost, { Result } from './lcc'
import CIAHeader from './transactions'
import MassRecommend, {MassRecommendResult, MassRecommendUpload} from './massrecommend';

import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_CalistaAdvisory'

const MODULE_TITLE = 'Calista Intelligent Advisory'


export const QuickSearchPage = props => {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      renderHeader={crumbs => <CIAHeader
        crumbs={crumbs}
        title={MODULE_TITLE}
      />}
      renderPage={(showSnackbar) => (
        <TASContext>
          <QuickSearch showNotification={showSnackbar} {...props} />
        </TASContext>
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export const CalculatorPage = props => {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      renderHeader={crumbs => <CIAHeader
        crumbs={crumbs}
        title={MODULE_TITLE}
      />}
      renderPage={(showSnackbar) => (<>
        <LandedCost showNotification={showSnackbar} {...props} />
      </>)}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}
export const ResultPage = props => {
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      renderHeader={crumbs => <CIAHeader
        crumbs={crumbs}
        title='Landed Cost Calculator'
      />}
      renderPage={(showSnackbar) => (
        <Result showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export const MassRecommendPage = props => {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderHeader={crumbs => <CIAHeader
                crumbs={crumbs}
                title={'CIA'}
            />}
            renderPage={(showSnackbar) => (<MassRecommend />)}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export const MassRecommendUploadPage = props => {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderHeader={crumbs => <CIAHeader
                crumbs={crumbs}
                title={'CIA'}
            />}
            renderPage={(showSnackbar) => (<MassRecommendUpload />)}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

export const MassRecommendResultPage = props => {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderHeader={crumbs => <CIAHeader
                crumbs={crumbs}
                title={'CIA'}
            />}
            renderPage={(showSnackbar) => (<MassRecommendResult />)}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}



