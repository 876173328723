import {
  Box,
  Typography
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

function FieldBox(props) {

  return (<Box color='text.textSecondary'>
    <Typography variant='caption'>
      {props.label}
    </Typography><br/>
    <Box component='span' color='text.cngBlack' {...props.ValueProps}>
      {props.children || props.value}&nbsp;
    </Box>
    <Typography component='span'>
      {props.extra}
    </Typography>
  </Box>)
}

export default FieldBox
