import {
  Box,
  Typography
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import CardMedia from './CardMedia'

const PUBLIC_URL = process.env.PUBLIC_URL

function ErrorContent(props) {

  return (<>
    {typeof props.children === 'string' ? (
      <Box component={Typography} pt={2} variant='h4' align='center'>
        {props.children}
      </Box>
    ) : props.children}

    <CardMedia
      image={props.image}
      height={props.height}
    />
  </>)
}

ErrorContent.defaultProps = {
  children: 'No data',
  image: PUBLIC_URL + '/static/images/graphics/no_result.svg',
  height: 300
}

export default ErrorContent
