import {
  Typography
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import CngTreeview from '../../../../components/treeview'
import ErrorContent from '../components/ErrorContent'

function Documents(props) {
  if (props.docs && props.docs.length)
    return <CngTreeview data={props.docs} showExpandedSiblings />
  else
    return <ErrorContent>No related documents</ErrorContent>
}

export default Documents
