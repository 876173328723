import {
  Avatar,
  Box,
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  text: { color: theme.palette.text.textSecondary },
  flag: {
    width: '1.1em',
    height: '1.1em'
  },
  box: {
    minWidth: '10vw',
    padding: theme.spacing(.25, 1.5, .5)
  }
}))
function CountryBox(props) {
  const classes = useStyles()

  return (<Box className={classes.box} style={props.style}>
    <Typography variant='caption' className={classes.text}>
      {props.title}
    </Typography>
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item>
        <Avatar
          src={`/static/images/countries/${props.code}.svg`}
          alt={`${props.code} flag`}
          className={classes.flag}
        />
      </Grid>
      <Grid item>{props.name}</Grid>
    </Grid>
  </Box>)
}

CountryBox.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default CountryBox
