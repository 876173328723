import * as Yup from 'yup'

export const HS_REGEX = new RegExp('(^[0-9]{6,13}$)+')

export const SEARCH_TYPES = [
  {text: 'Auto', value: 'auto'},
  {text: 'HS Code', value: 'hscode'},
  {text: 'Description', value: 'Description'}
]

export const INITIAL_VALUES = {
  type: 'auto',
  description: '',
  origin: '',
  destination: '',
  inputLanguage: 'en',
  outputLanguage: 'en',
  requestType: 'both'
}

export const validationSchema = Yup.object().shape({
  type: Yup.string()
    .required('Required')
    .oneOf(
      // uncomment this line out of demo, and comment the following 2 lines
      SEARCH_TYPES.map(s=>s.value)
      // DEMO ONLY (2 lines)
      // ['Predict', 'Chapter','Auto','HS Code','Description'],
      // "For DEMO, choose either 'Predict' or 'Chapter' only."
    )
})

export const LANGUAGE_INITIAL = {
  inputLanguage: '',
  outputLanguage: ''
}
