import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Box,
    Typography,
    Grid,
    makeStyles,
    DialogTitle
} from "@material-ui/core";
import { components, useServices } from 'cng-web-lib'
import {COLOR, manual, organizer} from "../../../tas/quick-search/helper";
import CngTabBar from "../../../../../components/tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import {HSBox} from "../../../tas/quick-search/components";
import CngTreeview from 'src/components/treeview'
import {TASContext} from "../../../../../store/intelligent-advisory";
import {BASIC_ITEM, DESTINATION, ORIGIN} from "../../../../../store/intelligent-advisory/tas";
import {CIAApiUrls} from "../../../../../apiUrls";
import {useSnackbar} from "notistack";

const {
    button: { CngPrimaryButton },
    CngGridItem
} = components

const useStyles = makeStyles(theme => ({
    paddingZero: {
        padding: 0 + ' !important'
    },
    dialogHeader: {
        background: '#F5F5FA65',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:last-child": {
            paddingBottom: theme.spacing(1)
        },
        background: '#E4EBF64D'
    },
    origin: {
        borderColor: COLOR.origin + ' !important'
    },
    destination: {
        borderColor: COLOR.destination + ' !important'
    },
    tabs: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    codeTableRoot: {
        width: '100%',
        borderSpacing: '5px',
        margin: theme.spacing(2),
        '& th': {
            background: '#1C1D210F',
            padding: theme.spacing(1, 2),
            fontSize: '.9em'
        }
    },
    header: {
        textAlign: 'center',
        '& > th': {
            '&:first-child': { borderTopLeftRadius: 15 },
            '&:last-child': {
                borderRadius: '0 15px 15px 0',
                width: 250
            }
        }
    },
    code: {
        background: '#1C1D2105',
        textAlign: 'center',
        fontSize: '3em',
        padding: theme.spacing(1.25),
        fontWeight: 300,
        '& > td > *': {
            minHeight: '1.7em',
            minWidth: '2.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    footer: {
        textTransform: 'uppercase',
        textAlign: 'center',
        '& > th': {
            '&:first-child': { borderBottomLeftRadius: 15 }
        }
    },
    detail: {
        fontWeight: 'normal',
        display: 'flex',
        textAlign: 'left',
        width: '20vw',
        padding: theme.spacing(1, 0),
        '& > svg': {
            marginRight: theme.spacing(1),
            marginTop: '.1em'
        },
        '& .MuiButton-root': {
            marginTop: theme.spacing()
        }
    },
    description: {
        color: '#8996AF'
    },
    treeCard: {
        boxShadow: 'none',
        margin: theme.spacing(2),
        width: '100%'
    },
    bottomBtn: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonTransform: {
        textTransform: 'none'
    },
    lightBtn: {
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: '#5E81F419',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: '#fff'
        }
    },
}))

export default function EditDialog(props) {
    const classes = useStyles()
    const [hsCodeTab, setHsCodeTab] = useState('')
    const [hsDescriptionTab, setHsDescriptionTab] = useState('')
    const [loading, setLoading] = useState(false);

    const context = React.useContext(TASContext)
    const { enqueueSnackbar } = useSnackbar()
    const { securedSendRequest } = useServices()
    const { fetchRecords } = useServices()

    useEffect(() => {handleResult()}, [context.activeDirection, props.editData])

    const treeHeaderRender = node => (<>
        <Typography className={classes.header}>{node.hs_description}</Typography>
        <HSBox code={node.hs_code} color={COLOR[context.activeDirection]} />
    </>)

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleTabChange = i => {
        let dir = i === 0 ? ORIGIN : DESTINATION
        context.setDirection(dir)
    }

    const handleActiveItemChange = (node, isActive, isExpanded, openItems) => {
        handleHeaderClicked(node, isActive, isExpanded, openItems)

        setHsCodeTab(node.hs_code)
        setHsDescriptionTab(node.hs_description)
    }

    const handleHeaderClicked = (node, isActive, isExpanded, openItems) => {
        if (node.isManual && node.hasChildren && !node.children && isExpanded) {
            let terms = {
                ...node.terms,
                griLevel: node.terms.griNextLevel,
                indent: node.indent+1, // required for manual search
                selectedHS: node.hs_code
            }
            fetchResults(terms).then(res => {
                [node.children] = manual.search(res, terms)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                context.setTreeData({
                    ...context.activeTree,
                    active: node,
                    expanded: openItems
                })
            })
        } else {
            context.setTreeData({
                ...context.activeTree,
                active: node,
                expanded: openItems
            })
        }
    }


    const handleResult = () => {
        if(props.editData[context.activeDirection] != null) {
            context.setTreeData({
                active: props.editData[context.activeDirection].active || BASIC_ITEM,
                expanded: props.editData[context.activeDirection].expanded || [],
                data: props.editData[context.activeDirection].data
            }, context.activeDirection)

            setHsCodeTab(props.editData[context.activeDirection].active != null ? props.editData[context.activeDirection].active.hs_code : '')
            setHsDescriptionTab(props.editData[context.activeDirection].active != null ? props.editData[context.activeDirection].active.hs_description : '')
        }
    }

    const updateHsCode = (uuid, ref, hsCode) => {
        setLoading(true)

        let url = CIAApiUrls.MASS_RECOMMEND_UPDATE;
        let data = new FormData();

        let requestType = context.activeDirection == ORIGIN ? 'import' : 'export';

        let activeTree = null
        let activeTreeTxt = null
        if(context.activeTree.data[0] != null && context.activeTree.data[0].isManual) {
            activeTree = context.activeTree
            activeTree.data = activeTree.data.filter(item => context.activeTree.expanded.includes(item.id))
            activeTreeTxt = JSON.stringify(activeTree)
            data.append('activeTree', activeTreeTxt);
        }

        data.append('uuid', uuid);
        data.append('ref', ref);
        data.append('hsCode', hsCode[requestType]);
        data.append('newHsCode', context.activeItem.hs_code);
        data.append('requestType', requestType);
        securedSendRequest.execute("POST", url, data,
            (response) => {
                if(response.data) {
                    props.data.dutiableHs[requestType] = context.activeItem.hs_code;
                    props.setData(props.data)
                    if(activeTree != null)
                        context.setTreeData({
                            active: activeTree.active,
                            expanded: activeTree.expanded,
                            data: activeTree.data
                        }, context.activeDirection)
                    handleClose()
                    enqueueSnackbar('Update successful!', { variant: 'success' })
                } else {
                    enqueueSnackbar('Failed to update. Please try again', { variant: 'error' })
                }
            },
            (error) => {
                console.error(error)
            },
            onComplete,
            {responseType: "boolean"}
        )
    }

    const onComplete = () => {
        setLoading(false)
    }

    const manualSearch = (data) => {

        let values = {}

        values.origin = data.origin
        values.destination = data.des
        values.searchType = 'gri'
        values.description = data.prodDes
        values.inputLanguage = 'en'
        values.outputLanguage = 'en'
        values.targetCountry = context.activeDirection === ORIGIN ? data.origin : data.des
        values.griLevel = 'predict'

        fetchRecords.execute(
            CIAApiUrls.GRISEARCH,
            { customData: values },
            data => {
                if (values.origin === values.targetCountry) {
                    context.setOriginToken(data.sessionToken)
                    values.type = 'origin'
                } else {
                    context.setDestinationToken(data.sessionToken)
                    values.type = 'destination'
                }
                values.sessionToken = data.sessionToken
                values.griLevel = 'chapter'
                doSearch(values)
            },
            error => console.log(error)
        )
    }

    const doSearch = (terms, direction = context.activeDirection) => {
        // reset this tree
        fetchResults(terms).then((res, values) => {
            handleManualResult(res, terms, direction)
        })
    }

    const fetchResults = (values) => new Promise((resolve, reject) => {
        fetchRecords.execute(
            CIAApiUrls.GRISEARCH,
            { customData: values },
            data => {
                if (values.origin === values.targetCountry)
                    context.setOriginToken(data.sessionToken)
                else
                    context.setDestinationToken(data.sessionToken)

                values.sessionToken = data.sessionToken;
                resolve(data)
            },
            error => reject(error)
        )
    })

    const handleManualResult = (newResult, terms, direction = context.activeDirection) => {
        let { status, errorMessage } = newResult
        if (status === 200) {
            let [results, expansion, lastChild] = organizer.parseResults(newResult, terms)

            let editResult = props.editData
            editResult[direction].data = results
            editResult[direction].active = lastChild || BASIC_ITEM
            editResult[direction].expanded = expansion
            props.setEditData(editResult)

            handleResult()
        } else if (errorMessage) {
            console.log(errorMessage)
        } else {
            console.log('No Result')
        }
    }

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth={'lg'}
            onClose={handleClose}
            scroll={'body'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle>Edit Entry</DialogTitle>
            <DialogContent className={classes.paddingZero}>
                <Grid container className={classes.dialogHeader}>
                    <CngGridItem xs={12} sm={4} >
                        <Box component={Card} m={2}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary">
                                    Reference no.
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {props.data.ref}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={4} >
                        <Box component={Card} m={2}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary">
                                    Origin
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {props.data.origin}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={4} >
                        <Box component={Card} m={2}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary">
                                    Destination
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {props.data.des}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={12} >
                        <Box component={Card} m={2}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary">
                                    URL Image
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {props.data.url}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CngGridItem>
                </Grid>
                {props.editData[context.activeDirection].data != null && <>
                <Grid container>
                    <Grid item className={classes.tabs}>
                        <CngTabBar
                            textColor='inherit'
                            value = {context.activeDirection == 'origin' ? 0 : 1}
                            tabs={[{
                                label: `Import`,
                                selectedClass: classes.origin,
                                disabled: props.productList.requestType.toLowerCase() == 'export' ? true : false,
                                icon: null
                            }, {
                                label: `Export`,
                                selectedClass: classes.destination,
                                disabled: props.productList.requestType.toLowerCase()== 'import' ? true : false,
                                icon: null
                            }]}
                            onActiveTabChanged={handleTabChange}
                        />
                    </Grid>
                    <CngGridItem xs={12} sm={10} >
                        <Box component={Card} m={2}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary">
                                    Product Description
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {props.data.prodDes}
                                </Typography>
                            </CardContent>
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={12} sm={2} >
                        <Box p={2} style={{height:'100%'}}>
                            <CngPrimaryButton
                                size='large'
                                startIcon={<FontAwesomeIcon icon={['fal', 'search']} fixedWidth size='sm' />}
                                type='submit'
                                style={{width:'100%', height:'100%'}}
                                disabled
                            >
                                Search
                            </CngPrimaryButton>
                        </Box>
                    </CngGridItem>
                </Grid>
                <Grid container>
                    <table className={classes.codeTableRoot}><tbody>
                    <tr className={classes.header}>
                        <th colSpan={3}>
                            Product category type
                        </th>
                        <th>Classification level</th>
                        <th rowSpan={3}>
                            <Box className={classes.detail}>
                                <FontAwesomeIcon icon={['fal', 'info-circle']} fixedWidth color={COLOR.origin} />
                                <div>
                                    <div>
                                        <b>HS Code - Import</b>
                                        <br/>
                                        <Typography variant='body2' className={classes.description} paragraph>
                                            {hsDescriptionTab || 'Please select a classification'}
                                        </Typography>
                                    </div>
                                    {props.data.dutiableHs && (
                                        <Button variant='outlined' color='primary'
                                                startIcon={<FontAwesomeIcon icon={['fal', 'copy']} fixedWidth />}
                                        >
                                            Copy {hsCodeTab}
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </th>
                    </tr>
                    <tr className={classes.code}>
                        <td><Box color={COLOR[context.activeDirection]}>{hsCodeTab && hsCodeTab.length >= 2 ? hsCodeTab.substring(0,2) : ''}</Box></td>
                        <td><Box color={COLOR[context.activeDirection]}>{hsCodeTab && hsCodeTab.length >= 4 ? hsCodeTab.substring(2,4) : ''}</Box></td>
                        <td><Box color={COLOR[context.activeDirection]}>{hsCodeTab && hsCodeTab.length >= 6 ? hsCodeTab.substring(4,6) : ''}</Box></td>
                        <td><Box color={COLOR[context.activeDirection]}>{hsCodeTab && hsCodeTab.length > 6 ? hsCodeTab.substring(6) : ''}</Box></td>
                    </tr>
                    <tr className={classes.footer}>
                        <th>Chapter</th>
                        <th>Heading</th>
                        <th>Sub-heading</th>
                        <th>HS Code</th>
                    </tr>
                    </tbody></table>

                    <Box className={classes.bottomBtn} sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h3" color="textPrimary">
                                HS Code Tree
                            </Typography>
                        </Box>
                        <Grid item container direction='row' justify='flex-end' alignItems='center'>
                            <Typography variant='body1'>
                                Suggestion: Not the results you are looking for?
                            </Typography>
                            <Button className={classes.lightBtn}
                                    variant='contained'
                                    onClick={() => manualSearch(props.data)}
                                    startIcon={<FontAwesomeIcon icon={['fal', 'arrow-circle-right']} fixedWidth />}
                            >
                                Search manually
                            </Button>
                        </Grid>
                    </Box>

                    <Card className={classes.treeCard}>
                        <CngTreeview
                            data={props.editData[context.activeDirection] != null && props.editData[context.activeDirection].data}
                            renderHeader={treeHeaderRender}
                            onToggle={handleActiveItemChange}
                            hideInactiveSiblings
                            expanded={context.activeTree.expanded}
                            active={context.activeItem.id}
                            autoCascade
                        />
                    </Card>
                </Grid> </> }
            </DialogContent>
            <Box className={classes.bottomBtn}>
                <Button className={classes.buttonTransform} variant="outlined" color="primary" onClick={handleClose}>Discard</Button>
                <Button className={classes.buttonTransform} endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />} variant="contained" color="primary" onClick={() => updateHsCode(props.productList.uuid, props.data.ref, props.data.dutiableHs)}>Save changes</Button>
            </Box>
        </Dialog>
    );
}