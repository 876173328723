import {
  Typography,

  useTheme, makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import HSBox from './hsCode'

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 600,
    fontSize: '.9em',
    lineHeight: 1.2,
    marginRight: theme.spacing()
  }
}))

function TreeHeader(props) {
  const classes = useStyles()
  const theme = useTheme()

  return (<>
    <Typography className={classes.header}>
      {props.description}
    </Typography>
    <HSBox code={props.code}
      color={props.color || theme.palette.common.white}
    />
  </>)
}

TreeHeader.propTypes = {
  code: PropTypes.string.isRequired
}

export default TreeHeader
