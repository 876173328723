import React from 'react'
import ControlHeader from './components/ControlHeader'
import ControlContent from './components/ControlContent'
import ListContent from './components/ListContent'
import DocHeader from './components/DocHeader'
import DocContent from './components/DocContent'
import ExplanatoryNotesHeader from './components/ExplanatoryNotesHeader'
import ExplanatoryNotesContent from './components/ExplanatoryNotesContent'

const externalLinkIcon = '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="external-link" class="svg-inline--fa fa-external-link fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M440,256H424a8,8,0,0,0-8,8V464a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V112A16,16,0,0,1,48,96H248a8,8,0,0,0,8-8V72a8,8,0,0,0-8-8H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V264A8,8,0,0,0,440,256ZM500,0,364,.34a12,12,0,0,0-12,12v10a12,12,0,0,0,12,12L454,34l.7.71L131.51,357.86a12,12,0,0,0,0,17l5.66,5.66a12,12,0,0,0,17,0L477.29,57.34l.71.7-.34,90a12,12,0,0,0,12,12h10a12,12,0,0,0,12-12L512,12A12,12,0,0,0,500,0Z"></path></svg>'

export const formatText = text => {
  if (!text || typeof text != "string")
    return text
  return text.replaceAll(
    /(https?:\/\/[^\s]+)/g,
    `<a href="$1" target="_blank" class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary">$1&nbsp;&nbsp;${externalLinkIcon}</a>`
  ).replaceAll(
    /([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)/g,
    `<a href="mailto:$1" target="_blank" class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorSecondary">$1&nbsp;&nbsp;${externalLinkIcon}</a>`
  ).replaceAll("\n", "<br/>").replaceAll(/[ ]{6}/g, "&emsp;&ensp;").replaceAll(/\t/g, "&emsp;")
}
export const formatColors = (text, colorClass, doFormat, regexp) => {
  if (!text || typeof text != "string")
    return text
  if (regexp)
    text = text.replaceAll(regexp, `<span class="${colorClass}">$&</span>`)
  else if (text.toLowerCase() === 'free')
    text = `<span class='${colorClass}'>FREE</span>`

  text = text.replaceAll(
    /\b(\d+\.)*\d+\%\B/g,
    `<span class="${colorClass}">$&</span>`
  )

  if (doFormat)
    return formatText(text)
  return text
}
const capitalizeWords = string => {
  return string.split('_').map(word => {
    let w = word
    // capitalize words
    if (word.length) {
      w = word[0].toUpperCase()
      w += word.substring(1)
    }
    return w
  }).join(' ')
}
const sortFnByKey = (first, second, key) =>
  first[key] < second[key] ? -1
  : (first[key] > second[key] ? 1
  : 0)

const overview = {
  basic: data => ({
    importHscode: data.importHsCode,
    importDescription: data.importHsDescription,
    importLocation: data.request.toCountry,
    importUom: data.importHsCodeUOM,
    exportHscode: data.exportHsCode,
    exportDescription: data.exportHsDescription,
    exportLocation: data.request.fromCountry,
    exportUom: data.exportHsCodeUOM
  }),
  footnotes: (explanatoryNotes, masterControlNotes) => {
    let ret = {
        exportFootNotes: [],
        importFootNotes: [],
    }
    let exportSectionNotes = {}
    exportSectionNotes.id = generateRandomNum() + ''
    exportSectionNotes.hasChildren = true
    exportSectionNotes.renderHeader = node => <ExplanatoryNotesHeader title={"Section Notes"} {...node} />
    exportSectionNotes.children = explanatoryNotes.exportNotes.section.map(f =>{
        let sect = {
          id: generateRandomNum() + '',
          hasChildren: true,
          name: f.title,
          details: f.details
        }

        sect.content = <ExplanatoryNotesContent details={f.details} />
        return sect
    })
    if (exportSectionNotes.children.length == 0)
    {
      exportSectionNotes.children.push({
        id: generateRandomNum() + '',
        hasChildren: false,
        name: "No Details Available..."
      });
    }
    ret.exportFootNotes.push(exportSectionNotes)

    let exportChapterNotes = {}
    exportChapterNotes.id = generateRandomNum() + ''
    exportChapterNotes.hasChildren = true
    exportChapterNotes.renderHeader = node => <ExplanatoryNotesHeader title={"Chapter Notes"} {...node} />
    exportChapterNotes.children = explanatoryNotes.exportNotes.chapter.map(f =>{
        let sect = {
          id: generateRandomNum() + '',
          hasChildren: true,
          name: f.title,
          details: f.details
        }

        sect.content = <ExplanatoryNotesContent details={f.details} />
        return sect
    })
    if (exportChapterNotes.children.length == 0)
    {
      exportChapterNotes.children.push({
        id: generateRandomNum() + '',
        hasChildren: false,
        name: "No Details Available..."
      });
    }
    ret.exportFootNotes.push(exportChapterNotes)

    let importSectionNotes = {}
    importSectionNotes.id = generateRandomNum() + ''
    importSectionNotes.hasChildren = true
    importSectionNotes.renderHeader = node => <ExplanatoryNotesHeader title={"Section Notes"} {...node} />
    importSectionNotes.children = explanatoryNotes.importNotes.section.map(f =>{
        let sect = {
          id: generateRandomNum() + '',
          hasChildren: true,
          name: f.title,
          details: f.details
        }

        sect.content = <ExplanatoryNotesContent details={f.details} />
        return sect
    })
    if (importSectionNotes.children.length == 0)
    {
      importSectionNotes.children.push({
        id: generateRandomNum() + '',
        hasChildren: false,
        name: "No Details Available..."
      });
    }
    ret.importFootNotes.push(importSectionNotes)

    let importChapterNotes = {}
    importChapterNotes.id = generateRandomNum() + ''
    importChapterNotes.hasChildren = true
    importChapterNotes.renderHeader = node => <ExplanatoryNotesHeader title={"Chapter Notes"} {...node} />
    importChapterNotes.children = explanatoryNotes.importNotes.chapter.map(f =>{
        let sect = {
          id: generateRandomNum() + '',
          hasChildren: true,
          name: f.title,
          details: f.details
        }

        sect.content = <ExplanatoryNotesContent details={f.details} />
        return sect
    })
    if (importChapterNotes.children.length == 0)
    {
      importChapterNotes.children.push({
        id: generateRandomNum() + '',
        hasChildren: false,
        name: "No Details Available..."
      });
    }
    ret.importFootNotes.push(importChapterNotes)

    let controlNotes = {}
    controlNotes.id = generateRandomNum() + ''
    controlNotes.hasChildren = true
    controlNotes.renderHeader = node => <ExplanatoryNotesHeader title={"Control Notes"} {...node} />
    controlNotes.content =  <ExplanatoryNotesContent details={masterControlNotes} />
    ret.importFootNotes.push(controlNotes)

    return ret
  },
  formalities: controls => {
    let ret = {
      importFormalities: controls.importControls,
      exportFormalities: controls.exportControls
    }
    // format for treeview
    ret.importFormalities.forEach(f => {
      f.id = generateRandomNum() + ''
      f.renderHeader = node => <ControlHeader {...node} />
      if (f.controlsContent && f.controlsContent.length) {
        f.hasChildren = true
        f.children = f.controlsContent.map(c => {
          let control = {
            id: generateRandomNum() + '',
            name: c.contentGroupTitle
          }

          if (c.contentGroupDetails && c.contentGroupDetails.length) {
            control.hasChildren = true
            if (f.controlsTitle === 'Controlling Agencies (CA) Details')
              control.content = <ListContent
                columns={[['title', 'Title'], ['description', 'Description'], ['uom', 'UOM']]}
                data={c.contentGroupDetails}
              />
            else
              control.content = <ControlContent data={c.contentGroupDetails} />
          }
          return control
        })
      }
      delete f.controls
    })

    // format for treeview
    ret.exportFormalities.forEach(f => {
      f.id = generateRandomNum() + ''
      f.renderHeader = node => <ControlHeader {...node} />
      if (f.controlsContent && f.controlsContent.length) {
        f.hasChildren = true
        f.children = f.controlsContent.map(c => {
          let control = {
            id: generateRandomNum() + '',
            name: c.contentGroupTitle
          }

          if (c.contentGroupDetails && c.contentGroupDetails.length) {
            control.hasChildren = true
            if (f.controlsTitle === 'Controlling Agencies (CA) Details')
              control.content = <ListContent
                  columns={[['title', 'Title'], ['description', 'Description'], ['uom', 'UOM']]}
                  data={c.contentGroupDetails}
              />
            else
              control.content = <ControlContent data={c.contentGroupDetails} />
          }
          return control
        })
      }
      delete f.controls
    })

    return ret
  },
  consolidateExportsImports: (data, treeMap) => {
    if (!data.length)
      return null
    data = data.reduce((acc, cur) => ({...cur, ...acc}), {})
    return Object.entries(data).filter(([k, v]) => v.length).map(([k, v]) => ({
      id: generateRandomNum() + '',
      name: capitalizeWords(k),
      hasChildren: true,
      children: v.map(treeMap)
    }))
  },
  procedures: procs => overview.consolidateExportsImports(procs, p => ({
    id: generateRandomNum() + '',
    name: p.name,
    hasChildren: true,
    content: <ListContent
      columns={[
        ['index', 'Index'],
        ['step', 'Name'],
        ['description', 'Description']
      ]}
      data={p.procedureDetails.sort((first, second) =>
        sortFnByKey(first, second, 'index')
      )}
    >
      {p.purpose}
    </ListContent>
  })),
  prohibited: proh => overview.consolidateExportsImports(proh, p => ({
    id: generateRandomNum() + '',
    name: p.description,
    hasChildren: true,
    content: <div>{p.note}</div>
  }))
}

const docs = {
  keyTitles: {
    docResponsibleParty: 'Responsible Party',
    docCategory: 'Category',
    source: 'Source'
  },
  fileIcons: {
    pdf: "pdf",
    doc: "word", docx: "word",
    ppt: "powerpoint", pptx: "powerpoint",
    zip: "archive", rar: "archive", tar: "archive", gz: "archive", "7z": "archive"
  },
  formatContent: doc => {
    let ret = {
      ...doc,
      id: generateRandomNum() + '',
      renderHeader: node => <DocHeader {...doc} />,
      hasChildren: true
    }
    let content = Object.entries(doc)
      .filter(([k, v]) => Object.keys(docs.keyTitles).includes(k))
      .map(([k, v]) => ({title: docs.keyTitles[k], value: formatText(v)}))

    let files
    if (doc.docLink) {
      let url = new URL(doc.docLink)
      let isInternal = url.pathname.startsWith('/cal-cia/tas/')

      let name = url.pathname.split('/').pop()
      let ext = name.split('.').pop().toLowerCase()
      let icon = docs.fileIcons[ext]
      if (!isInternal && !icon) // check if not file
        name = url.host

      icon = icon ? 'file-' + icon : 'globe'

      let link = isInternal ? url.href.replace(url.origin + '/cal-cia', process.env.REACT_APP_CIA_ORIGIN_URL) : doc.docLink

      files = [{
        name, icon, isInternal, link
      }]
    }

    ret.content = <DocContent
      content={content}
      description={doc.docDescription}
      downloads={files}
    />
    return ret
  }
}

export const formatControl = data => {
  let controlsFormatted = {
    controlCategory: [],
    masterControlNotes: ""
  };
  
  data.result.controls.controlCategory.forEach(cc => {
    const controlCategory = new Object();
    controlCategory.controlsTitle = cc.controlsTitle;
    controlCategory.controlsApplied = cc.controlsApplied;
    controlCategory.controls = [];

    if(cc.hasChildren === true) {
      cc.children.forEach(c => {
        const controls = new Object();
        controls.content_group_title = c.name;
        controls.content_group_details = [];
        if(c.hasChildren === true) {
          c.content.props.data.forEach(p => {
            const contentGroupDetails = new Object();
            contentGroupDetails.description = p.description;
            contentGroupDetails.title = p.title;
            contentGroupDetails.uom = p.uom;
            controls.content_group_details.push(contentGroupDetails);
          });
        }
        controlCategory.controls.push(controls);
      });
    }

    controlsFormatted.controlCategory.push(controlCategory);
  })

  controlsFormatted.masterControlNotes = data.result.masterControlNotes != null ? data.result.masterControlNotes : "-";

  return controlsFormatted;
}

export const formatOverviewData = data => ({
  ...overview.basic(data),
  ...(data.controls && overview.formalities(data.controls)),
  ...(data.explanatoryNotes && overview.footnotes(data.explanatoryNotes, data.masterControlNotes)),
  //procedures: data.procedures ? overview.procedures(data.procedures) : null,
  //prohibited: data.prohibitedList ? overview.prohibited(data.prohibitedList) : null,
})

export const formatDuties = data => {
  let duties = data.taxAndDuties
  if (!duties || !duties.length)
    return null
  duties.forEach(d => {
    d.tax.forEach(t => t.id = generateRandomNum() + '')
  })
  return duties
}

export const formatDocs = data => {
  let ret = data.documents
  if (!ret || !ret.length)
    return null
  return ret.filter(dt =>
    dt.docs.flatMap(d => d.docSet).length
  ).map(docType => ({
    id: generateRandomNum() + '',
    name: docType.doctype,
    hasChildren: true,
    children: docType.docs.filter(docSet =>
      docSet.docSet.length
    ).map(docSet => ({
      id: generateRandomNum() + '',
      name: docSet.docControlType,
      hasChildren: true,
      children: docSet.docSet.map(docs.formatContent)
    })).sort(({ docControlType: a }, { docControlType: b }) => {
      if (a < b)
        return -1
      return (a > b) ? 1 : 0
    })
  }))
}

function generateRandomNum() {
  const cryp = window.crypto || window.msCrypto;
  const tab = new Uint32Array(1);

  return cryp.getRandomValues(tab)[0] / 0x100000000;
}