export const VALID_STATES = {
  valid: {
    color: 'text.success',
    text: 'Valid!',
    tooltip: 'HS Code provided is valid! Please proceed to enter quantities.',
    icon: 'check'
  },
  invalid: {
    color: 'text.error',
    text: 'Invalid',
    tooltip: 'Invalid HS Code entered. Please try another code.',
    icon: 'times'
  },
  unknown: {
    color: 'secondary',
    text: 'Validate',
    tooltip: 'Enter a HS Code and click here to validate, or search for a HS Code manually using the next button.'
  },
  validating: {
    color: 'secondary',
    text: 'Validating...',
    tooltip: 'Please wait...',
    icon: 'spinner-third',
    iconProps: {spin: true}
  }
}
