import * as Yup from 'yup'

export const INITIAL_VALUES = {
  // NOTE: commented-out fields are copied from old implementation. unsure if required.
  // private
  currencySymbol: '',
  // END private

  exportCountry: '',
  // exportCountryText: '',
  importCountry: '',
  // importCountryText: '',
  manufactureOrigin: '',
  // manufactureCountryText: '',

  check_hsCode: '', // this is the manual text input
  hsItem: null,
  // should be an array in the future
  hsCode: '',
  // hscodeDesc: '',
  // ... UOM fields added dynamically

  // uom
  measures: [],

  incoterm: '',
  currencyCode: '',
  merchandiseValue: '',

  shipment: false,
  oneShipmentPerPersonPerDay: '',

  mot: '',
  importDate: '',

  // page 2
  freightInlandOrigin: '',
  freightInlandDestination: '',
  freightInternational: '',

  originCharge: '',
  destinationCharge: '',
  insurance: '',


}

const ERRORS = {
  number: 'Please enter a valid number.',
  date: 'Please select a valid date.'
}

const REQUIRED_MESSAGE = {
  merchandiseValue_msg: 'Incoterm value is required.',
  fieldReqMsg : 'This field is required.'
}

//Form validate
export const VALIDATION_SCHEMA = [
  Yup.object().shape({
    merchandiseValue: Yup.number().typeError(ERRORS.number).required(REQUIRED_MESSAGE.fieldReqMsg),
    uomList: Yup.array().of(Yup.object().shape({
      uom: Yup.number().typeError(ERRORS.number),
      type: Yup.string().required()
    })),
    measures: Yup.array().of(Yup.object().shape({
      measureQuantity: Yup.number().required(REQUIRED_MESSAGE.fieldReqMsg).typeError(ERRORS.number)
    })),

    incoterm: Yup.string().required(REQUIRED_MESSAGE.fieldReqMsg),
    currencyCode: Yup.string().required(REQUIRED_MESSAGE.fieldReqMsg),
    mot: Yup.string().required(REQUIRED_MESSAGE.fieldReqMsg),
    importDate: Yup.date().validFormat().required(REQUIRED_MESSAGE.fieldReqMsg).typeError(ERRORS.date)

  }),
  Yup.object().shape({
    freightInlandOrigin: Yup.number().typeError(ERRORS.number),
    freightInlandDestination: Yup.number().typeError(ERRORS.number),
    freightInternational: Yup.number().typeError(ERRORS.number),

    originCharge: Yup.number().typeError(ERRORS.number),
    destinationCharge: Yup.number().typeError(ERRORS.number),
    insurance: Yup.number().typeError(ERRORS.number),
    // TODO: other PAGE 2 validation
  })
]

export const INCOTERMS_REQUIRED = {
  freightInlandOrigin: ['EXW'],
  freightInlandDestination: ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAP'],
  freightInternational: ['EXW', 'FCA', 'FAS', 'FOB'],

  originCharge: ['EXW', 'FCA', 'FAS'],
  destinationCharge: ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CIF', 'CPT', 'CIP', 'DAP'],
  insurance: ['EXW', 'FCA', 'FAS', 'FOB', 'CFR', 'CPT', 'DAT', 'DAP'],
}
