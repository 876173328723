import {
  Box,
  IconButton,
  Tooltip
} from '@material-ui/core'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'

import { FieldArray } from 'formik'
import { useSnackbar } from 'notistack'
import { handleErrors, getUOMSymbols } from 'src/store/intelligent-advisory'
import { VALID_STATES } from './helper'

import { FadedButton } from 'src/components/button'
import SearchDialog from './components/SearchDialog'
import HSInfo from './components/HSInfo'

import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { components, constants, useServices } from 'cng-web-lib'

const {
  form: {
    adapter: {
      useFormAdapter: {useField, useFormikContext}
    },
    field: {CngTextField}
  }
} = components

function HsCodeSearch(props) {
  const { createRecord, fetchRecords, fetchRecord, exportRecords, securedSendRequest } = useServices()
  const formik = useFormikContext()
  const {
    formState: {
      isDirty, errors, ...formState
    },
    ...form
  } = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  const watchFields = form.watch()
  const [validity, setValidity] = useState(VALID_STATES.unknown)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (validity === VALID_STATES.validating) {
      form.setValue('hsItem', null)
      form.setValue('uomList', [])
    }
  }, [validity])

  useEffect(() => {
    if (!isDirty)
      setValidity(VALID_STATES.unknown)
  }, [isDirty])

  const params = useMemo(() => ({
    fromCountry: watchFields.exportCountry,
    toCountry: watchFields.importCountry,
    searchType: 'hscode',
    itemDescription: watchFields.check_hsCode
  }), [watchFields])

  const handleRemoveItem = () => {
    setValidity(VALID_STATES.unknown)
    form.setValue('hsItem', null)
    form.setValue('uomList', [])
    form.setValue('isValid', false);
  }

  //After manual search of description or HSCode 
  const applyItem = item => new Promise((resolve, reject) => {
    setValidity(VALID_STATES.validating)
    form.setValue('isValid', false);
    // Get UOM
    fetchRecords.execute(
      CIAApiUrls.UOM,
      { customData: {
        hscode: item.hsCode,
        country: form.getValues('importCountry'),
        classtype: 'IMPORT'
      }},
      data => {
        if(data.status == 200 && data.result == null) {
          form.setValue('isValid', true);
          setValidity(VALID_STATES.valid)
        } else {
          item.uomList = getUOMSymbols(data.result)
          if (item.uomList.length){
            form.setValue('measures',
              item.uomList.map(([measureUnit, displayUnit]) => ({
                measureUnit, displayUnit,
                measureQuantity: ''
              }))
            )
          }
          form.setValue('isValid', true);
          setValidity(VALID_STATES.valid)
        }
      },
      error => {
        form.setValue('isValid', false);
        handleErrors(enqueueSnackbar, error)
        setValidity(VALID_STATES.invalid)
        reject(error)
      },
      done => {
        console.log(item)
        form.setValue('hsItem', item)
        form.setValue('check_hsCode', item.hsCode)
      }
    )
  })

  //Validation of HSCode is being handled
  const handleValidation = () => {
    if (!form.getValues('importCountry'))
      return enqueueSnackbar(
        'Please select country of destination.', { variant: 'error'}
      )
    form.setValue('isValid', false);
    setValidity(VALID_STATES.validating)
    fetchRecords.execute(
      CIAApiUrls.GRISEARCH_V2,
      { customData:
        {
          searchText: form.getValues('check_hsCode'),
          targetCountry : form.getValues('importCountry'),
          inputLanguage : 'en',
          outputLanguage : 'en',
          griLevel : 'predict',
          searchType : 'hscode'
        }
      },
      response => {
        if (!response.results || !response.results.length)
          throw new Error('No matching code found')
        let result = findLastDutiableChild(response.results[0])
        console.log(result)
        if (!result)
          throw new Error('No matching code found')

        form.setValue('hsItem', result)
        applyItem(result)
        form.setValue('isValid', true);
        form.trigger("measures");
      },
      error => {
        form.setValue('isValid', false);
        setValidity(VALID_STATES.invalid)
        handleErrors(enqueueSnackbar, error)
      }
    )

    const findLastDutiableChild = (node) => {
      console.log(node)
      if (node.children) {
        const children = node.children;
        if (children.length > 0) {
          return findLastDutiableChild(children[children.length - 1]);
        }
      }
      if (node.type === 'dutiable') {
        return node;
      }
      return null;
    };

//    axios(`/static/tas/lcc/hs_code_${params.itemDescription || 'error'}.json`, { params }).then(res => {
//      if (res.status != 200 || !res.data)
//        throw new Error('Error fetching data.')
//      let response = res.data
//      if (response.status !== 200)
//        throw response.errorMessage
//      if (!response.results.length)
//        throw new Error('No matching code found')
//      let result = response.results[0].results
//                      .find(x => x.type === 'dutiable')
//      if (!result)
//        throw new Error('No matching code found')
//
//      applyItem(result)
//
//    }).catch(err => {
//      console.log('ERROR', err)
//      setValidity(VALID_STATES.invalid)
//      handleErrors(enqueueSnackbar, err)
//    })
  }

  const handleOpenDialog = () => {
    if (!form.getValues('importCountry'))
      return enqueueSnackbar(
        'Please select country of destination.', { variant: 'info'}
      )
    setOpen(true)
  }


  return (<>
    <CngTextField
      label='Import HS Code'
      name='check_hsCode'
      onFocus={() => setValidity(VALID_STATES.unknown)}
      disabled={validity === VALID_STATES.validating}
      helperText={errors.hsItem}
      error={Boolean(errors.hsItem)}
      size='small'
      InputProps={{
        endAdornment: (<>
          <Box mx={1}><Tooltip title={validity.tooltip}><span>
            <FadedButton customColor={validity.color}
              startIcon={validity.icon &&
                <FontAwesomeIcon
                  icon={['fal', validity.icon]}
                  {...validity.iconProps}
                  fixedWidth
                />
              }
              disabled={validity === VALID_STATES.validating}
              onClick={handleValidation}
            >
              {validity.text}
            </FadedButton>
          </span></Tooltip></Box>
          <Tooltip title='Manually search for HS Code'><span>
            <IconButton
              className='cng-cia'
              onClick={handleOpenDialog}
              disabled={validity === VALID_STATES.validating}
            >
              <FontAwesomeIcon icon={['fal', 'search']} fixedWidth size='xs' />
            </IconButton>
          </span></Tooltip>
        </>)
      }}
    />
    {watchFields.hsItem && <HSInfo
      {...watchFields.hsItem}
      onRemove={handleRemoveItem}
    />}
    <SearchDialog open={open}
      onClose={() => setOpen(false)}
      onApply={applyItem}
    />
  </>)
}

export default HsCodeSearch
