import {
    Divider,
    Grid,
    Paper,
    Box,
    TextField,
    Button,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import ResultTable from "./components/ResultTable";
import { useServices } from 'cng-web-lib'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import pathMap from "../../../../paths/PathMap_CalistaAdvisory";
import {CIAApiUrls} from "../../../../apiUrls";

import { Context as TASContext } from 'src/store/intelligent-advisory/tas'

const useStyles = makeStyles(theme => ({
    docInfo: {
        padding: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    bottomBtn: {
        maxWidth: '100%',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topMenuLabel: {
        padding: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#8996AF'
    },
    AnalyseLabel: {
        padding: theme.spacing(1.25),
        fontWeight: 'bold',
        fontSize: "medium",
        display: 'flex',
        alignItems: 'center'
    },
    topMenu: {
        padding: theme.spacing(1.25),
        margin: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    docInstruction: {
        display: 'flex',
        flexDirection: "column",
        color: '#8996AF',
        fontWeight: "lighter",
        fontSize: "smaller"
    },
    marginRight: {
        marginRight: theme.spacing(1.25)
    },
    textBold: {
        fontWeight: "bold"
    },
    padding2: {
        padding: theme.spacing(2),
    },
    buttonTransform: {
        textTransform: 'none'
    },
    listItemEnd: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
}))

function MassRecommendResult(props) {

    const classes = useStyles()
    const history = useHistory()
    const { securedSendRequest } = useServices()

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([])
    const [keyword, setKeyword] = useState('')
    const [rowCount, setRowCount] = useState(0)

    const productList = history.location.state.productList

    const handleClickHome = () => {
        history.push(pathMap.CALISTA_ADVISORY_MASSRECOMMEND)
    }

    function generateReportExcel() {

        setLoading(true)

        console.log(result)

        let excelReport = result

        excelReport.map((e) => {
            e.importHs = e.dutiableHs.import
            e.exportHs = e.dutiableHs.export
        })

        let url = CIAApiUrls.MASS_RECOMMEND_GENERATE_REPORT_EXCEL;
        let data = new FormData();
        let resultTxt = JSON.stringify(excelReport);

        console.log(resultTxt)

        data.append('result', resultTxt);
        securedSendRequest.execute("POST", url, data,
            (response) => {
                let blob = response.data
                let fileName = "mass_recommend_result_["+productList.uuid+"].xlsx";

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);

                //console.log(link)
                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            },
            (error) => {
                console.error(error)
            },
            onComplete,
            {responseType: "blob"}
        )
    }

    function onComplete() {
        setLoading(false)
    }

    let filter = (e) => {
        setKeyword(e.target.value)
    }

    return (
        <Paper>
            <Box px={3} >
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemText classes={{ primary: classes.textBold }} primary={productList.productName != null ? productList.productName : "Product Name"} secondary={productList.productLists.length + " entries"} />
                        <ListItemText disableTypography
                                      primary={<Typography variant="body2" color="textSecondary">Trade direction type</Typography>}
                                      secondary={<Typography variant="body1" color="textPrimary">{productList.requestType}</Typography>} />
                    </ListItem>
                </List>
            </Box>
            <Divider />
            <Box mt={2}>
                <Grid container spacing={1} alignItems="center" className={classes.padding2} >
                    <Grid item xs={8}>
                        <span style={{color: '#8996AF'}}>{"Showing " + rowCount + " entries."}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.listItemEnd}>
                        <Box mr={2}>
                            <IconButton>
                                <FontAwesomeIcon icon={['fal', 'filter']} />
                            </IconButton>
                        </Box>
                        <TextField InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={['fal', 'search']} />
                                </InputAdornment>
                            )
                        }} fullWidth variant="outlined" onChange={filter} placeholder="Search in your entries"/>
                    </Grid>
                </Grid>
                <TASContext>
                    <ResultTable productList={productList} setResult={setResult} setRowCount={setRowCount} keyword={keyword}/>
                </TASContext>
                <Box className={classes.bottomBtn}>
                    <Button className={classes.buttonTransform} variant="outlined" color="primary" onClick={() => handleClickHome()}>Back</Button>
                    <Button className={classes.buttonTransform} disabled={loading} endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />} variant="contained" color="primary" onClick={generateReportExcel}>Download excel</Button>
                </Box>
            </Box>
        </Paper>);
}

export default MassRecommendResult
