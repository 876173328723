import {Button, ListItemIcon, makeStyles, Menu, MenuItem, Typography} from '@material-ui/core'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { useHistory } from 'react-router-dom'
import React from 'react'
import clsx from "clsx"

import pathMap from 'src/paths/PathMap_CalistaAdvisory'

const toolbarStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiMenu': {
            '&-paper': { borderRadius: 10 },
            '&-list': {
                maxWidth: '60vw',
                padding: 2,
                '& .MuiListItem-root:not(.Mui-disabled)': {
                    letterSpacing: 0.21,
                    fontSize: '.9em',
                    padding: '8px 10px',
                    minWidth: '12em',
                    '&:hover': {
                        backgroundColor:
                        theme.palette.component.popoverMenu.listItemHoverFill
                    }
                }
            }
        },
        '& .ng-mattbl-popover-checkbox': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .ng-mattbl-popover-title': {
            backgroundColor:
            theme.palette.component.textFormControl.dropdownMenuSelectedFill,
            borderRadius: 8,
            fontWeight: 900,
            color: theme.palette.text.navActive,
            padding: '10px 8px',
            marginBottom: theme.spacing(0.5)
        }
    },
    buttonTransform: {
        textTransform: 'none'
    },
}))

function RequestTypeDropdown() {
    const toolbarClasses = toolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const history = useHistory()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickUpload = (requestType) => {
        history.push(pathMap.CALISTA_ADVISORY_MASSRECOMMEND_UPLOAD + '/' + requestType);
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                color="primary"
                disableElevation
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
                endIcon={<FontAwesomeIcon icon={['fas', 'caret-down']} />}
                onClick={handleClick}
                className={clsx(toolbarClasses.buttonTransform)}
            >
                New request
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ horizontal: "center" }}
                className={clsx(toolbarClasses.menu)}
            >
                <MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
                    {'Request Type'}
                </MenuItem>
                <MenuItem onClick={() => handleClickUpload("import")}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </ListItemIcon>
                    <Typography variant="inherit">Import country</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleClickUpload("export")}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                    </ListItemIcon>
                    <Typography variant="inherit">Export country</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleClickUpload("both")}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={['fas', 'exchange']} />
                    </ListItemIcon>
                    <Typography variant="inherit">Import & export country</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default RequestTypeDropdown