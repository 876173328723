import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar, ListItemText,
    makeStyles,
    TextField
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import { useServices } from 'cng-web-lib'
import {DropzoneAreaBase} from "material-ui-dropzone";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DocumentInfoDecoratorComponent from "../../../../common/ui/DocumentInfoDecoratorComponent";
import Paper from "@material-ui/core/Paper";
import {CIAApiUrls} from "../../../../../apiUrls";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import {bytesToSize} from "../../utils";
import ErrorLogDropdown from "./ErrorLogDropdown";
import {none} from "ramda";

const useStyles = makeStyles(theme => ({
    docInfo: {
        padding: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    topMenuLabel: {
        padding: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#8996AF'
    },
    topMenu: {
        padding: theme.spacing(1.25),
        margin: theme.spacing(1.25),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    docInstruction: {
        display: 'flex',
        flexDirection: "column",
        color: '#8996AF',
        fontWeight: "lighter",
        fontSize: "smaller"
    },
    bottomBtn: {
        padding: theme.spacing(1.25),
        marginTop: theme.spacing(3),
        fontSize: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    listItemAvatar: {
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    listItemEnd: {
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center'
    },
    textAlignEnd: {
        textAlign:'end'
    },
    marginRight2: {
        marginRight: theme.spacing(2)
    },
    buttonTransform: {
        textTransform: 'none'
    },
}))

function UploadFileCard(props) {

    const classes = useStyles()
    const { securedSendRequest } = useServices()

    const [loading, setLoading] = useState(false);
    const [pendingDocs, setPendingDocs] = useState(null)
    const [productName, setProductName] = useState("")
    const [errorLog, setErrorLog] = useState(null)

    const { enqueueSnackbar } = useSnackbar()

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function generateTemplate() {

        let url = CIAApiUrls.MASS_RECOMMEND_GENERATE_TEMPLATE;
        let data = {}
        securedSendRequest.execute("GET", url, data,
            (response) => {
                let blob = response.data
                let fileName = "Mass Recomendation Sample Input.xlsx";

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);

                //console.log(link)
                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            },
            (error) => {
                console.error(error)
            },
            onComplete,
            { responseType: "blob" }
        )
    }

    function onComplete() {
        console.log("complete")
    }

    function onAddFile(fileObjs) {
        setPendingDocs(fileObjs[0])
        setErrorLog(null)
    }

    function onDiscard() {
        setPendingDocs(null)
        setErrorLog(null)
        setProductName("")
    }

    function uploadDocAjax() {

        if(pendingDocs == null) {
            enqueueSnackbar('Please upload file first.', { variant: 'error' })
            return
        }

        if(productName === '') {
            enqueueSnackbar('Please enter file name.', { variant: 'error' })
            return
        }

        setErrorLog(null)
        setLoading(true)

        let url = CIAApiUrls.MASS_RECOMMEND_UPLOAD_DOC
        let data = new FormData();
        data.append('docItem', pendingDocs.file);
        data.append('productName', productName);
        data.append('requestType', props.requestType);
        securedSendRequest.execute("POST", url, data,
            (response) => {
                if(response.data != null && response.data.status == 200) {
                    props.setDocFileDetail({productName: productName, fileName: pendingDocs.file.name,
                        fileSize: pendingDocs.file.size, uuid: response.data.uuid, records: response.data.records })
                        props.setUploadState('uploaded')
                } else if(response.data.status == 400) {
                    setErrorLog(response.data.errorMessage)
                    enqueueSnackbar('There are some errors detected inside the file. Please fix it and try again', { variant: 'error' })
                }
            },
            (error) => {
                console.error(error)
            },
            () => {  setLoading(false) }
        )
    }

    return (
        <div>
            <Box className={classes.topMenu}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#5E81F4" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>1</span>

                        </span>
                    <span style={{fontWeight: 'bold'}}>Upload & Analyse</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                        <span className="fa-layers fa-fw fa-2x">
                            <FontAwesomeIcon icon={['fas', 'circle']} color="#1C1D2133" />
                            <span className="fa-layers-text" style={{ color: 'white', fontSize: 'small' }}>2</span>

                        </span>
                    <span>Finish</span>
                </div>
            </Box>

            <Box mb={1.5}>
                <Divider />
            </Box>

            <Box className={classes.topMenuLabel}>
                <span>
                    {props.requestType === 'import' && <FontAwesomeIcon className={classes.marginRight2} icon={['fas', 'arrow-right']} /> }
                    {props.requestType === 'export' && <FontAwesomeIcon className={classes.marginRight2} icon={['fas', 'arrow-left']} /> }
                    {props.requestType === 'both' && <FontAwesomeIcon className={classes.marginRight2} icon={['fas', 'exchange']} /> }
                    {props.requestType === 'both' ? ' Import & export country' : capitalize(props.requestType)}
                </span>
                <Button className={classes.buttonTransform} variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={['fal', 'download']} />} onClick={() => generateTemplate()}>Template excel</Button>
            </Box>

            {errorLog != null &&
            <Box component={Card} mx={1.5} mb={3} px={3} >
                <List sx={{ bgcolor: 'background.paper' }}>
                    <Grid container spacing={1} alignItems="center">
                        <ListItem>
                            <Grid item xs={5} className={classes.listItemAvatar} >
                                <ListItemAvatar>
                                    <FontAwesomeIcon icon={['fal', 'file-alt']} />
                                </ListItemAvatar>
                                <ListItemText primary={pendingDocs.file.name} secondary={bytesToSize(pendingDocs.file.size,2 )} />

                            </Grid>
                            <Grid item xs={5}>
                                <ListItemText disableTypography
                                              primary={<Typography variant="body2" color='textSecondary'>Name</Typography>}
                                              secondary={<Typography variant="body1" color="textPrimary">{productName}</Typography>} />
                            </Grid>
                            <Grid item xs={1} className={classes.textAlignEnd}>
                                <ListItemText primary={<Typography  variant="h6" color='error'>File Error</Typography>} />
                            </Grid>
                            <Grid item xs={1} className={classes.listItemEnd}>
                                <ErrorLogDropdown errorLog={errorLog}/>
                            </Grid>
                        </ListItem>
                    </Grid>
                </List>
            </Box> }

            <DropzoneAreaBase
                acceptedFiles={[
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                maxFileSize={10485760}
                onAdd={(fileObjs) => onAddFile(fileObjs)}

            />
            <Box mt={1.5} mb={2} className={classes.docInstruction}>
                    <span>
                        Accepted formats: *.xls *.xlxs with not more than XX MB file size.
                    </span>
                <span>
                        Maximum 1 file per upload. Kindly download and use our template before importing it here.
                    </span>
            </Box>
            {pendingDocs != null &&
            <Box component={Paper} mt={1.5}>
                <Box className={classes.docInfo}>
                    <FileCopyIcon />
                    <DocumentInfoDecoratorComponent name={pendingDocs != null ? pendingDocs.file.name : '-'} size={pendingDocs != null ? pendingDocs.file.size : 0} />
                    <Box className={classes.title}>
                        <TextField fullWidth={true} variant="outlined" label="Name" required
                                   value={productName}
                                   onChange={e => setProductName(e.target.value)}/>
                    </Box>
                </Box>
            </Box> }

            <Box className={classes.bottomBtn}>
                <Button className={classes.buttonTransform} variant="outlined" color="primary" onClick={() => onDiscard()}>Discard</Button>
                <Button className={classes.buttonTransform} disabled={loading} endIcon={loading && <FontAwesomeIcon icon={['fal', 'spinner-third']} fixedWidth spin />} variant="contained" color="primary" disabled={pendingDocs === null} onClick={() => uploadDocAjax()}>Upload</Button>
            </Box>
        </div>

    );
}

export default UploadFileCard