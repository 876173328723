import {
  Box,
  Chip,
  Grid,
  Tooltip,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  chip: {
    background: '#5E81F425',
    color: theme.palette.primary.main
  }
}))

function DocHeader(props) {
  const classes = useStyles()
  return (
    <Grid container spacing={1} wrap='nowrap' alignItems='center'>
      <Grid item xs>{props.docName}</Grid>
      {props.docResponsibleParty && props.docResponsibleParty.split(',').map((party, i) =>
        <Grid item key={i}>
          <Chip color='secondary' size='small'
            className={classes.chip}
            label={party}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default DocHeader
