import {
  Box,
  Card,
  Grid,

  makeStyles
} from '@material-ui/core'
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { LandedCostContext } from 'src/store/intelligent-advisory'
import { FadedButton } from 'src/components/button'
import ItemInfo from './ItemInfo'

import DetailDialog from './DetailDialog'

function InfoCard(props) {
  const [isDetailOpen, setDetailOpen] = useState(false)

  return (<Card variant='outlined'><Box p={1}>
    <ItemInfo simple>
      <Grid item>
        <FadedButton color='primary' onClick={() => setDetailOpen(true)}>
          Details
        </FadedButton>
      </Grid>
    </ItemInfo>
    <DetailDialog
      open={isDetailOpen}
      onClose={() => setDetailOpen(false)}
    />
  </Box></Card>)
}

export default InfoCard
