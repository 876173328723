import {
  Box,
  Button,
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { TASContext } from 'src/store/intelligent-advisory/tas'

import { getHSCode } from '../../quick-search/helper'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles(theme => ({
  hsDetailImport: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    background: '#e8f5e9',
    padding: theme.spacing(),
    borderRadius: 8,
    fontSize: '.9rem',
    cursor: 'pointer',
    '& > svg': {
      color: theme.palette.success.light,
      marginRight: theme.spacing()
    }
  },
  hsDetailExport: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    background: 'rgba(244, 190, 94, 0.08)',
    padding: theme.spacing(),
    borderRadius: 8,
    fontSize: '.9rem',
    cursor: 'pointer',
    '& > svg': {
      color: theme.palette.warning.light,
      marginRight: theme.spacing()
    }
  },
  hsButton: {
    padding: theme.spacing(1, 1.5),
    fontSize: '1.3em',
    fontWeight: 'bold',
    background: 'rgba(28, 29, 33, 0.06)',
    borderRadius: 4,
    '& *': { lineHeight: 'initial' },
    '& .MuiButton-endIcon': { marginRight: 0 }
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5)
  }
}))

function HSInfo(props) {
  const classes = useStyles()
  const { copyCode } = React.useContext(TASContext)


  const handleImportExpandClick = () => {
    props.setExpanded({...props.expanded, import:!props.expanded.import})
  };

  const handleExportExpandClick = () => {
    props.setExpanded({...props.expanded, export:!props.expanded.export})
  };

  return (
    <Grid container className={props.requestTypeInfo == 'import' ? classes.hsDetailImport : classes.hsDetailExport}
          spacing={1}
    >
      <FontAwesomeIcon icon={['fal', 'info-circle']} size='2x' className={classes.marginLeft} />
      <Grid item xs
            onClick={props.requestTypeInfo == 'import' ? handleImportExpandClick : handleExportExpandClick}>
        <b>HS Code ({props.requestTypeInfo == 'import' ? 'Import' : 'Export'}) - {props.requestTypeInfo == 'import' ? props.importLocation : props.exportLocation}</b><br/>
        <Typography color='textSecondary' variant='body2'>
          {props.requestTypeInfo == 'import' ? props.importDescription : props.exportDescription}
        </Typography>
      </Grid>
      <Grid item>
        <Button className={classes.hsButton}
          endIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
          onClick={()=>copyCode(props.requestTypeInfo == 'import' ? props.importHscode : props.exportHscode)}
        >
          {getHSCode(props.requestTypeInfo == 'import' ? props.importHscode : props.exportHscode).join(' ')}
        </Button>
      </Grid>
      {(props.requestTypeInfo == 'import' ? props.importUom : props.exportUom) && <Grid item><Box className={classes.hsButton}>
        UOM:  {props.requestTypeInfo == 'import' ? props.importUom : props.exportUom}
      </Box></Grid>}
      <Grid item onClick={props.requestTypeInfo == 'import' ? handleImportExpandClick : handleExportExpandClick}>
        <IconButton
            variant='contained'
            color='default'
            size='small'
        >
            {(props.requestTypeInfo == 'import' ? props.expanded.import : props.expanded.export)
                ? <ExpandLessIcon />
                : <ExpandMoreIcon />
            }
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default HSInfo
