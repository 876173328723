import {} from '@material-ui/core'
import React from 'react'

import Home from './home'

function MassRecommend(props) {
    return <Home/>
}

export default MassRecommend
export { default as MassRecommendUpload } from './upload'
export { default as MassRecommendResult } from './result'