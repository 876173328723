import {
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import React, { useMemo } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import CngSelectField from 'src/components/select/CngSelectField'
import { components } from 'cng-web-lib'
const {
  // form: {
  //   field: { CngSelectField }
  // },
  CngDialog,
  button: { CngPrimaryButton }
} = components


function LanguageDialog(props) {

  const langIn = useMemo(() => props.langIn.map(o => ({
    text: <><b>{o.code.toUpperCase()}</b>&ensp;{o.descriptionEn}</>,
    value: o.code
  })), [props.langIn])

  const langOu = useMemo(() => props.langOu.map(o => ({
      text: <><b>{o.code.toUpperCase()}</b>&ensp;{o.descriptionEn}</>,
      value: o.code
  })), [props.langOu])


  return (<CngDialog
    open={props.open}
    fullWidth
    maxWidth='xs'
    dialogTitle={<Grid container wrap='nowrap' justify='space-between'>
      <Typography variant='h5'><b>Language options</b></Typography>
      <Tooltip title='Close'>
        <IconButton className='cng-cia' size='small'
          onClick={props.onClose}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </IconButton>
      </Tooltip>
    </Grid>}
    customDialogContent={<DialogContent>
      <Typography gutterBottom variant='body2'>Currently searching in</Typography>
      <Box mt={1} mb={3}>
        <CngSelectField
          label='Search language'
          name='inputLanguage'
          items={langIn}
          size='small'
        />
      </Box>
      <Typography gutterBottom variant='body2'>and showing search results in</Typography>
      <Box mt={1} mb={3}>
        <CngSelectField
          label='Result language'
          name='outputLanguage'
          items={langOu}
          size='small'
        />
      </Box>
      <Box mt={1} mb={1}>
        <CngPrimaryButton color='primary' onClick={props.onClose} fullWidth>
          Apply
        </CngPrimaryButton>
      </Box>
    </DialogContent>}
  />)
}

export default LanguageDialog
