import {makeStyles, Menu, MenuItem, IconButton, ListItemIcon, Typography} from '@material-ui/core'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React from 'react'
import clsx from "clsx"

const toolbarStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiMenu': {
            '&-paper': { borderRadius: 10 },
            '&-list': {
                maxWidth: '60vw',
                padding: 2,
                '& .MuiListItem-root:not(.Mui-disabled)': {
                    letterSpacing: 0.21,
                    fontSize: '.9em',
                    padding: '8px 10px',
                    minWidth: '12em',
                    '&:hover': {
                        backgroundColor:
                        theme.palette.component.popoverMenu.listItemHoverFill
                    }
                }
            }
        },
        '& .ng-mattbl-popover-checkbox': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .ng-mattbl-popover-title': {
            backgroundColor:
            theme.palette.component.textFormControl.dropdownMenuSelectedFill,
            borderRadius: 8,
            fontWeight: 900,
            color: theme.palette.text.navActive,
            padding: '10px 8px',
            marginBottom: theme.spacing(0.5)
        }
    }
}))

const options = [
    'Newest first',
    'Oldest first',
    'Name: A — Z',
    'Name: Z — A',
];

function SortDropdown(props) {
    const toolbarClasses = toolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        props.setSortOrder(index);

    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={['fal', 'sort-alt']} size="xs" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ horizontal: "center" }}
                className={clsx(toolbarClasses.menu)}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} onClick={(event) => handleMenuItemClick(event, index)}>
                        <ListItemIcon>
                            {index === selectedIndex && <FontAwesomeIcon icon={['fas', 'check']} /> }
                        </ListItemIcon>
                        <Typography variant="inherit">{option}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default SortDropdown