import {
  Avatar,
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { LandedCostContext } from 'src/store/intelligent-advisory'

import { FadedButton } from 'src/components/button'
import CountryBox from './CountryBox'

const useStyles = makeStyles(theme => ({
  manufactured: {
    color: theme.palette.text.textSecondary,
    display: 'flex',
    alignItems: 'center'
  },
  truncate: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    order: -2
  },
  chip: {
    borderColor: theme.palette.divider,
    fontWeight: 'bold',
    fontSize: '1em'
  },
  flag: {
    width: '.9em',
    height: '.9em',
    margin: theme.spacing(0, 1)
  }
}))

function ItemInfo({simple, ...props}) {
  const classes = useStyles()
  const { displayResult: { importHSCodeDetail }, countryDetails } = useContext(LandedCostContext)
  const findLastDutiableChild = (node) => {
    console.log(node)
    if (node.children) {
      const children = node.children;
      if (children.length > 0) {
        return findLastDutiableChild(children[children.length - 1]);
      }
    }
    if (node.type === 'dutiable') {
      return node;
    }
    return null;
  };

  const details = findLastDutiableChild(importHSCodeDetail[0])

  return (<Grid container
    spacing={2}
    alignItems='center'
    justify='space-between'
    wrap={simple ? 'nowrap' : 'wrap'}
  >
    <Grid item
      xs={simple ? false : 3}
      style={{order: -3, [!simple && 'paddingBottom']: 0}}
    >
      <Chip variant='outlined'
        color='primary' size={simple ? 'small' : 'medium'}
        label={details.hsCode}
        className={classes.chip}
      />
    </Grid>

    <Grid item xs={simple || 12}
      className={simple ? classes.truncate : null}
    >
      <Tooltip title={simple ? details.hsDescription : ''} placement='bottom-start'>
        <span>{details.hsDescription}</span>
      </Tooltip>
    </Grid>
    {countryDetails && <>
      {!simple && <Grid item xs={12}>
        <Box className={classes.manufactured + ' MuiTypography-body2'}>
          Manufactured in
          <Avatar
            src={`/static/images/countries/${countryDetails.manufacturer.code}.svg`}
            alt={countryDetails.manufacturer.code}
            className={classes.flag}
          />
          {countryDetails.manufacturer.name}
        </Box>
      </Grid>}
      <Grid item xs={simple ? false : 3} style={{order: -1}}>
        <Grid container spacing={1}
          alignItems='center'
          wrap='nowrap'
          justify={!simple && 'space-between'}
        >
          <CountryBox
            title='Origin'
            {...countryDetails.origin}
            style={{paddingLeft: 0,
            [!simple && 'alignSelf']: 'start'}}
          />
          <FontAwesomeIcon icon={['fal', 'arrow-right']} fixedWidth />
          {simple && <CountryBox
            title='Destination'
            {...countryDetails.destination}
          />}
        </Grid>
      </Grid>
      {!simple && <Grid item xs style={{
          order: -1,
          [!simple && 'alignSelf']: 'start'
        }}>
        <CountryBox
          title='Destination'
          {...countryDetails.destination}
          style={{paddingLeft: 0}}
        />
      </Grid>}
    </>}
    {props.children}
  </Grid>)
}

export default ItemInfo
