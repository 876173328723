import {
  Box,
  makeStyles,
  lighten
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { getHSCode } from '../helper'

const useStyles = makeStyles(theme => ({
  hs: {
    margin: -4,
    marginLeft: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',

    '& > *': {
      display: 'inline-block',
      padding: theme.spacing(1),
      margin: 2,
      borderRadius: 4,
      color: theme.palette.common.black,
      fontWeight: 'bolder',
      fontSize: '1em'
    }
  }
}))

function HSBox(props) {
  const classes = useStyles()

  return (<Box className={classes.hs}>
    {getHSCode(props.code).map((hs, i) => <Box key={i} bgcolor={lighten(props.color, i * .2)}>{hs}</Box>)}
  </Box>)
}

HSBox.defaultProps = {
  color: '#7CE7AC'
}

HSBox.propTypes = {
  color: PropTypes.string,
  code: PropTypes.string.isRequired
}

export default HSBox
