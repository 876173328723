import {
  Box,
  Button,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { TASContext } from 'src/store/intelligent-advisory/tas'

import { getHSCode } from '../helper'

const useStyles = makeStyles(theme => ({
  root: {
    borderSpacing: '5px',
    margin: '0 auto',
    '& th': {
      background: '#1C1D210F',
      padding: theme.spacing(1, 2),
      fontSize: '.9em'
    }
  },
  header: {
    '& > th': {
      '&:first-child': { borderTopLeftRadius: 15 },
      '&:last-child': {
        borderRadius: '0 15px 15px 0',
        width: 250
      }
    }
  },
  code: {
    background: '#1C1D2105',
    textAlign: 'center',
    fontSize: '3em',
    padding: theme.spacing(1.25),
    fontWeight: 300,
    '& > td > *': {
      minHeight: '1.7em',
      minWidth: '2.5em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  footer: {
    textTransform: 'uppercase',
    '& > th': {
      '&:first-child': { borderBottomLeftRadius: 15 }
    }
  },
  detail: {
    fontWeight: 'normal',
    display: 'flex',
    textAlign: 'left',
    width: '20vw',
    padding: theme.spacing(1, 0),
    '& > svg': {
      marginRight: theme.spacing(1),
      marginTop: '.1em'
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing()
    }
  },
  description: {
    color: '#8996AF'
  }
}))

function CodeTable(props) {
  const classes = useStyles()
  const {
    activeDirection: direction,
    activeItem,
    ...context
  } = React.useContext(TASContext)


  return (<table className={classes.root}><tbody>
    <tr className={classes.header}>
      <th colSpan={3}>
        Product category type
      </th>
      <th>Classification level</th>
      <th rowSpan={3}>
        <Box className={classes.detail}>
          <FontAwesomeIcon icon={['fal', 'info-circle']} fixedWidth color={props.color} />
          <div>
            <div>
              <b>HS Code ({direction}) - {props.location}</b>
              <br/>
              <Typography variant='body2' className={classes.description} paragraph>
                {activeItem.hs_description || 'Please select a classification'}
              </Typography>
            </div>
            {activeItem.hs_code && (
              <Button variant='outlined' color='primary'
                onClick={() => context.copyCode(activeItem.hs_code)}
                startIcon={<FontAwesomeIcon icon={['fal', 'copy']} fixedWidth />}
              >
                Copy {getHSCode(activeItem.hs_code).join(' ')}
              </Button>
            )}
          </div>
        </Box>
      </th>
    </tr>
    <tr className={classes.code}>
      {activeItem.hs_code
        ? getHSCode(activeItem.hs_code).map((c, i) => <td key={i}>
          <Box color={props.color}>{c}</Box>
        </td>)
        : (<td>&nbsp;</td>)
      }
    </tr>
    <tr className={classes.footer}>
      <th>Chapter</th>
      <th>Heading</th>
      <th>Sub-heading</th>
      <th>HS Code</th>
    </tr>
  </tbody></table>)
}

CodeTable.defaultProps = {
  color: 'primary.main'
}

CodeTable.propTypes = {
  color: PropTypes.string,
  location: PropTypes.string.isRequired
}

export default CodeTable
