import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,

  makeStyles
} from '@material-ui/core'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'

import CountryAutocompleteField from 'src/components/autocomplete/CountryAutocompleteField'
import { FadedButton } from 'src/components/button'
import CngSelectField from 'src/components/select/CngSelectField'

import HsCodeSearch from './HsCodeSearch'
import { ChipOption, IconOption, TextOption } from './components/options'
import { currencyAdornment } from '../components'

import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { LandedCostContext, getPartyID } from 'src/store/intelligent-advisory'

import { components } from 'cng-web-lib'
const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngDateField,
      CngSwitchField
    }
  },
  button: { CngPrimaryButton },

} = components

var sortBy = require('lodash.sortby')

const CURR_BOX_SIZE = '1.5em'
const useStyles = makeStyles(theme => ({
  optionIcons: {
    width: CURR_BOX_SIZE, height: CURR_BOX_SIZE,
    fontWeight: 'bold',
    fontSize: '1.2em'
  },
  shipmentBox: {
    borderRadius: 10,
    padding: theme.spacing(0, 1),
    background: '#FAFBFC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    '& label': {
      marginLeft: 0,
      height: '100%'
    }
  }
}))

const TRANSPORT_OPTIONS = [
  {value: 'SEA', text: 'Sea', icon: 'ship'},
  {value: 'AIR', text: 'Air', icon: 'plane'},
  {value: 'LAND', text: 'Land', icon: 'truck-container'},
]

function CargoForm(props) {
  const _isMounted = React.useRef(true)
  const formik = useFormikContext()
  const form = useFormContext()
  const classes = useStyles()
  const {
    currencyOptions, setCurrencyOptions
  } = useContext(LandedCostContext)
  const importCountry = form.watch('importCountry')

  const [incotermOptions, setIncotermOptions] = useState([])

  useEffect(() => {
    let val = form.getValues('currencyCode')
    if (val)
      form.setValue('currencySymbol',
        (currencyOptions.find(c => c.code === val) || {}).symbol
      )
  }, [form.getValues('currencyCode'), currencyOptions])

  useEffect(() => {
    getOptions()
    return () => _isMounted.current = false
  }, [])

  const getOptions = isMounted => {
    axios(CIAApiUrls.INCOTERMS).then(data =>
      _isMounted.current && setIncotermOptions(data.data.map(i =>
        ({
          value: i.code,
          text: <ChipOption chip={i.code} text={i.description} />
        })
      ))
    )
    axios(CIAApiUrls.CURRENCY).then(data => {
      let mapped = data.data.map(i => ({
        ...i,
        value: i.code,
        text: <TextOption start={i.code} end={i.symbol} text={i.description} />
      }) )
      if (_isMounted.current)
        setCurrencyOptions(sortBy(mapped, ['value']))
    })
  }


  return (<>
    <Box component={Grid} container direction='column' spacing={4} pb={5}>
      {/* Cargo details */}
      <Grid item><Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' gutterBottom><b>Cargo details</b></Typography>
        </Grid>
        <Grid item xs={12} sm>
          <CountryAutocompleteField
            label='Country of origin'
            name='exportCountry'
            accessor='countryList'
            url={CIAApiUrls.ORGN_CTRY}
            lookupProps={{customData: {
              party_id: getPartyID()
            }}}
            required
            size='small'
          />
        </Grid>
        <Grid item xs={12} sm>
          <CountryAutocompleteField
            label='Country of destination'
            name='importCountry'
            accessor='countryList'
            url={CIAApiUrls.ORGN_CTRY}
            lookupProps={{customData: {
              party_id: getPartyID()
            }}}
            required
            size='small'
          />
        </Grid>
        <Grid item xs={12} sm>
          <CountryAutocompleteField
            label='Country of manufacture'
            name='manufactureOrigin'
            accessor='countryList'
            url={CIAApiUrls.ORGN_CTRY}
            lookupProps={{customData: {
              party_id: getPartyID()
            }}}
            required
            size='small'
          />
        </Grid>
      </Grid></Grid>
      {/* END Cargo details */}

      <Grid item>
        <HsCodeSearch />
      </Grid>

      {/* Incoterms */}
      <Grid item><Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <CngSelectField
            label='Incoterm'
            name='incoterm'
            size='small'
            items={incotermOptions}
            helperText={<>
              International commercial terms
              {form.formState.errors.incotermOptions &&
                ' - ' + form.formState.errors.incotermOptions
              }
            </>}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CngSelectField
            label='Incoterm currency'
            name='currencyCode'
            size='small'
            items={currencyOptions}
            helperText={<>
              The currency of the freight and insurance amounts. This is also the currency for the calculated duty and tax amount.
              {form.formState.errors.incotermOptions &&
                <><br/>{form.formState.errors.incotermOptions}</>
              }
            </>}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CngTextField
            label='Incoterm value'
            name='merchandiseValue'
            size='small'
            InputProps={{
              startAdornment: currencyAdornment(form)
            }}
            helperText={<>
              Value of the merchandise
              {form.formState.errors.incotermValue &&
                ' - ' + form.formState.errors.incotermValue
              }
            </>}
            required
          />
        </Grid>
      </Grid></Grid>
      {/* END Incoterms */}

      {/* Shipment Details */}
      <Grid item><Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' gutterBottom><b>Shipment details</b></Typography>
        </Grid>
        <Grid item xs={12} sm md={4}>
          <CngSelectField
            label='Mode of transportation'
            name='mot'
            items={TRANSPORT_OPTIONS.map(t=>({
              ...t,
              text: <IconOption icon={t.icon} text={t.text} />
            }))}
            required
            size='small'
          />
        </Grid>
        <Grid item xs={12} sm md={4}>
          <CngDateField
            label='Shipment date'
            name='importDate'
            required
            size='small'
          />
        </Grid>
        {importCountry === 'US' && <Grid item xs={12} sm={4}>
          <FormGroup className={classes.shipmentBox}>
            <FormControlLabel control={<CngSwitchField
              name='shipment'
            />} label='One shipment, per person, per day'
              labelPlacement='start'
            />
          </FormGroup>
        </Grid>}
      </Grid></Grid>
      {/* END Shipment Details */}
    </Box>
  </>)
}

export default CargoForm
