export const BASIC_ITEM = {
    "hs_code": '',
    "type": '',
    "indent": 0.0,
    "hs_description": '',
    "uom": [],
    "str_confid": '',

}

export const bytesToSize = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}


export const formatResult = (listItem) => {
    let productRow = []

    listItem.productLists.forEach((item) => {

        console.log(item)

        let conLvl = item.hsCode[0].result.confidence
        let remark = "Normal confidence state."
        let dutiableHs = {'import': '', 'export': ''}
        let hsResult = {'import': {}, 'export': {}}
        let manualResult = {'import': '', 'export': ''}

        if(conLvl < 70)
            remark = "Low confidence state."

        item.hsCode.forEach((hsCode) => {
            console.log(hsCode.result.activeTree)
            let result = hsCode.result.results != null ? hsCode.result.results.slice(-1).pop() : null
            if(hsCode.requestType == "import" && result != null) {
                dutiableHs.import = hsCode.result.activeHsCode != null && hsCode.result.activeHsCode != '' ? hsCode.result.activeHsCode : result.hs_code
                hsResult.import = hsCode.result.results
                manualResult.import = hsCode.result.activeTree
            } else if(hsCode.requestType == "export" && result != null) {
                dutiableHs.export = hsCode.result.activeHsCode != null && hsCode.result.activeHsCode != '' ? hsCode.result.activeHsCode : result.hs_code
                hsResult.export = hsCode.result.results
                manualResult.export = hsCode.result.activeTree
            }
        })

        productRow.push(createData(conLvl, item.itemDescription, item.fromCountry, item.toCountry, dutiableHs, remark, hsResult, item.ref, item.imageURL, manualResult))
    })

    return productRow
};

export const parseResult = (item) => {
    let importResult = []
    let exportResult = []
    let resultList = {import: [], export: []}

    if(item.import.length > 0) {
        let prevItem = {}
        item.import.forEach((result) => {
            let importItem = {}

            importItem.hs_code = result.hs_code
            importItem.indent = result.indent
            importItem.type = result.type
            importItem.hs_description = result.hs_description

            if(importItem.type == 'filler')
                importItem.id = prevItem.hs_code + 'f'
            else
                importItem.id = result.hs_code

            importResult.push(importItem)
            prevItem = result
        })
    }

    if(item.export.length > 0) {
        let prevItem = {}
        item.export.forEach((result) => {
            let exportItem = {}

            exportItem.hs_code = result.hs_code
            exportItem.indent = result.indent
            exportItem.type = result.type
            exportItem.hs_description = result.hs_description

            if(exportItem.type == 'filler')
                exportItem.id = prevItem.hs_code + 'f'
            else
                exportItem.id = result.hs_code

            exportResult.push(exportItem)
        })
    }

    if(importResult.length > 0) {
        importResult.forEach((node) => {
            resultList.import = findParent(importResult, node)
        })
    }

    if(exportResult.length > 0) {
        exportResult.forEach((node) => {
            resultList.export = findParent(exportResult, node)
        })
    }

    return resultList
}


function createData(level, prodDes, origin, des, dutiableHs, remark, hsResult, ref, url, activeTree) {
    return { level, prodDes, origin, des, dutiableHs, remark, hsResult, ref, url, activeTree };
}


const findParent = (list, node) => {

    list.forEach((item) => {
        let fillerChild = false

        if(item.type == 'filler' && (node.indent - item.indent) == 1
            && node.id.startsWith(item.id.substring(0,item.id.length-1))) {
            fillerChild = true
        }

        if(((node.indent - item.indent) == 1 && node.id.startsWith(item.id)) || fillerChild) {
            if(item.children == null)
                item.children = []

            node.parentId = item.id
            item.children.push(node)
            item.hasChildren = true
        }
    })

    return list
}

export const findExpendedPath = (node, id, expandedPath) => {

    if(node.parentId == null || (node.parentId != null && node.parentId != id && node.children != null))
        node.children.forEach((child) => {
            expandedPath = findExpendedPath(child, id, expandedPath)
        })

    if(node.id == id || node.id == expandedPath.parentId) {
        expandedPath.parentId = node.parentId
        expandedPath.path.push(node.id)
    }

    return expandedPath
}

export const findActive = (node, id, active) => {

    if(node.id != id && node.children != null)
        node.children.forEach((child) => active = findActive(child, id, active))

    if(node.id == id)
        return node

    return active
}