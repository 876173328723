import {
  Box,
  Card,
  Grid
} from '@material-ui/core'
import React, { useContext, useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import { TASContext, BASIC_ITEM, ORIGIN, DESTINATION } from 'src/store/intelligent-advisory/tas'
import { LandedCostContext, handleErrors, handleErrorsV2_1 } from 'src/store/intelligent-advisory'

import SearchResult from './quick-search'
import EAdvisory from './eAdvisory'
import CardMedia from './components/CardMedia'
import Searchbar from './components/Searchbar'
import CIAApiUrls from 'src/apiUrls/CIAApiUrls'
import { components, constants, useServices } from 'cng-web-lib';
import { organizer } from './quick-search/helper';

import { INITIAL_VALUES as initialValues, HS_REGEX } from './search';
import CountryDialog from "./components/CountryDialog";

const {
  form: { CngForm }
} = components
const { FormState } = constants

const PUBLIC_URL = process.env.PUBLIC_URL


function QuickSearch(props) {
  const { enqueueSnackbar } = useSnackbar()
  const { triggerTransaction } = React.useContext(LandedCostContext)

  const [isLoading, setIsLoading] = useState(false)

  const [eAdvisoryOn, setEAdvisoryOn] = useState(false)
  const [eAdvisoryData, setEAdvisoryData] = useState(null)
  const [disable, setDisable] = useState(false);
  const [openCountryDialog, setOpenCountryDialog] = useState(false)
  const [eAdvisoryCountry, setEAdvisoryCountry] = useState({toCountryCode: '', fromCountryCode: '', toCountryLabel: '', fromCountryLabel: ''})

  const { fetchRecords } = useServices()

  const {
    sessionToken,
    originToken,
    destinationToken,
    copyAlerts,
    activeDirection,
    activeItem,
    activeTree,
    originTree, destinationTree, requestType,
    ...context
  } = useContext(TASContext)

  useEffect(() => {
    if (copyAlerts.length) {
      let code = copyAlerts.pop()
      enqueueSnackbar(code.message, {
        variant: 'info'
      })
    }
  }, [copyAlerts])

  const handleHeaderClicked = (node, isActive, isExpanded) => {

    if (node.isManual && node.hasChildren && !node.children && isExpanded) {

      let terms = {
        ...node.terms,
        griLevel: node.terms.griNextLevel,
        indent: node.indent+1, // required for manual search
      }

      if(node.type !== 'filler')
        terms.griLevelHsCode = node.hs_code

      setIsLoading(true)
      fetchResults(terms, true).then(res => {
        let [results] = organizer.parseV2Result(res, terms)

        if(results[0].remark != null) {
          handleErrors(enqueueSnackbar, results[0].remark)
        } else {
          let nodeChildren = organizer.findNodeChildren(results[0], node.hs_code)
          nodeChildren.children.forEach((c) => {
            c.parentId = node.id
            if(c.children === undefined && c.type === 'filler') {
              console.log('no children for filler...')
              c.hasChildren = false
            }
          })

          nodeChildren.children = nodeChildren.children.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.indent === value.indent && t.hs_description === value.hs_description
              ))
          )

          node.children = nodeChildren.children
        }
      }).catch(err => {
        handleErrors(enqueueSnackbar, err)
      }).finally(() => setIsLoading(false))
    }
  }

  const handleEAdvisorySubmit = () => {

    if(eAdvisoryCountry.fromCountryCode == '' || eAdvisoryCountry.toCountryCode == '') {
      enqueueSnackbar('Please select the country pair first.', { variant: 'error' })
    } else {
      setOpenCountryDialog(false)
      setIsLoading(true)
      setDisable(true); //To set the button disable while loading
      fetchRecords.execute(
          CIAApiUrls.COMPLIANCE_V2_1,
          { customData: {
              fromCountry: eAdvisoryCountry.fromCountryCode,
              toCountry: eAdvisoryCountry.toCountryCode,
              importHsCode: requestType === 'export' ? "" : destinationTree.active.hs_code,
              exportHsCode: requestType === 'import' ? "" : originTree.active.hs_code,
            } },
          data => {
            data.status = 200;
            setEAdvisoryData(data);
            setEAdvisoryOn(true)
          },
          error => handleErrorsV2_1(enqueueSnackbar, error),
          done => {
            setIsLoading(false)
            setDisable(false); //To un-disable the button after load
            triggerTransaction()
          }
      )
    }

  }

  const handleEAdvisory = (on) => {
    if (on && requestType != 'both') {
      if (activeItem && activeItem.type === 'dutiable') {
        setOpenCountryDialog(true)
      } else {
        enqueueSnackbar('Please select a dutiable item first.', { variant: 'warning' })
      }
    } else if (on && requestType == 'both') {
      if (activeItem && activeItem.type === 'dutiable') {
        handleEAdvisorySubmit()
        setOpenCountryDialog(false)
      } else {
        enqueueSnackbar('Please select a dutiable item first.', { variant: 'warning' })
      }
    } else {
      setEAdvisoryOn(false)
      setEAdvisoryData({})
    }
  }


  const handleResult = (newResult, terms, direction = activeDirection) => {

    let [results, expansion, lastChild] = organizer.parseV2Result(newResult, terms)

    context.setTreeData({
      active: lastChild || BASIC_ITEM,
      expanded: expansion || [],
      data: {...newResult, results, label: terms[direction+'_label']}
    }, direction)

    context.setLoading(false, direction)
    triggerTransaction()
  }

  const handleManualSearch = values => onSubmit(values, null, true)

  const onSubmit = (values, evt, manualSearch) => {
    console.log(values)

    if(values.description === '')
      enqueueSnackbar('Please enter hscode or description first.', { variant: 'error' })
    else if(values.requestType === 'both' && (values.origin === '' || values.destination === ''))
      enqueueSnackbar('Please select both origin and destination country.', { variant: 'error' })
    else if(values.requestType === 'import' && values.destination === '')
      enqueueSnackbar('Please select destination country.', { variant: 'error' })
    else if(values.requestType === 'export' && values.origin === '')
      enqueueSnackbar('Please select origin country.', { variant: 'error' })
    else {
      setIsLoading(true)
      let terms = {}
      if (manualSearch) {

        if(isHSCode(values.description)) {
          values.type = 'gri'
          values.griLevel = 'predict'
        } else {
          values.griLevel = 'chapter'
        }

        terms = {
          ...values,
          targetCountry: values[activeDirection],
          sessionToken: activeDirection === ORIGIN ? originToken : destinationToken
        }
      } else {
        terms = {
          ...values,
          griLevel: 'predict'
        }
      }
      // clear active page
      setEAdvisoryOn(false)

      if (manualSearch)
        doSearch(terms, manualSearch)
      else {
        if(values.requestType === 'both') {
          doSearch({
            ...terms,
            targetCountry: values.origin,
            targetType: "export"
          }, null, ORIGIN)
          doSearch({
            ...terms,
            targetCountry: values.destination,
            targetType: "import"
          }, null, DESTINATION)
        } else if(values.requestType === 'export') {
          doSearch({
            ...terms,
            targetCountry: values.origin,
            targetType: "export"
          }, null, ORIGIN).then(() => context.setDirection(ORIGIN))
        } else if(values.requestType === 'import') {
          doSearch({
            ...terms,
            targetCountry: values.destination,
            targetType: "import"
          }, null, DESTINATION).then(() => context.setDirection(DESTINATION))
        }
      }
    }
  }

  const doSearch = (terms, manualSearch, direction = activeDirection) => new Promise(resolve => {
    // reset this tree
    context.resetDirection(direction)
    context.setLoading(true, direction)

    if(!manualSearch)
      context.setDirection(ORIGIN)

    fetchResults(terms, manualSearch).then((res, values) => {

      if(res.results == null) {
        handleErrors(enqueueSnackbar, res.remark)
        context.setLoading(false, direction)
        triggerTransaction()

        if(direction === ORIGIN)
          context.setDirection(DESTINATION)
      } else {
        handleResult(res, terms, direction)
      }
      resolve()
    }).catch(err => {
      handleErrors(enqueueSnackbar, err)
    }).finally(() => setIsLoading(false))
  })

  const fetchResults = (values, isManual) => new Promise((resolve, reject) => {

    let stripped = values.description.trim().replaceAll(' ', '')

    if (values.type === 'hscode')
      values.searchType = 'hscode'
    else
      values.searchType = (values.type === 'auto' && HS_REGEX.test(stripped)) ? 'hscode' : 'gri'

    if (values.searchType === 'hscode')
      values.description = stripped

    values.searchText = values.description

    fetchRecords.execute(
      CIAApiUrls.GRISEARCH_V2,
      { customData: values },
      data => {
        if (values.origin === values.targetCountry)
            context.setOriginToken(data.sessionToken)
        else
            context.setDestinationToken(data.sessionToken)

        values.sessionToken = data.sessionToken;
        values.griNextLevel = data.griNextLevel
        context.setRequestType(values.requestType)

        if(values.requestType === 'import') {
          setEAdvisoryCountry({...eAdvisoryCountry, fromCountryCode: '', fromCountryLabel: '', toCountryCode: values.destination, toCountryLabel: values.destination_label})
        } else if(values.requestType === 'export') {
          setEAdvisoryCountry({...eAdvisoryCountry, fromCountryCode: values.origin, fromCountryLabel: values.origin_label, toCountryCode: '', toCountryLabel: ''})
        } else {
          setEAdvisoryCountry({...eAdvisoryCountry, fromCountryCode: values.origin, toCountryCode: values.destination})
        }

        console.log(eAdvisoryCountry)

        resolve(data)
      },
      error => reject(error)
    )
  })

  const isHSCode = (inputString) => {
    // Use a regular expression to check if the string is a valid HS code
    return /^\d{6}$|^\d{8}$|^\d{10}$|^\d{12}$/.test(inputString);
  }

  return (<Box my={2}><CngForm
  formikProps={{
    initialValues, onSubmit
  }}
  formState={FormState.COMPLETED}
  renderButtonSection={()=>false}
  renderBodySection={() =>
    <Grid container direction='column' spacing={2}>
      {/* Searchbar */}
      <Grid item>
        <Searchbar isLoading={isLoading} />
      </Grid>

      {/* Data Display */}
      <Grid item>
        <Card variant='outlined'>
          {!eAdvisoryOn ? (
            (!originTree.data && !destinationTree.data) ?
              <CardMedia image={PUBLIC_URL + '/static/images/graphics/search.svg'} />
            : (<>
              <SearchResult
                origin={originTree.data}
                destination={destinationTree.data}
                onHeaderClicked={handleHeaderClicked}
                onManualSearch={handleManualSearch}
                onEAdvisory={() => handleEAdvisory(true)}
                disable = {disable}
              />
            </>)
          ) : <EAdvisory data={eAdvisoryData}
            onClose={() => handleEAdvisory(false)}
          />}

          <CountryDialog
              open={openCountryDialog}
              setOpenCountryDialog={setOpenCountryDialog}
              eAdvisoryCountry = {eAdvisoryCountry}
              setEAdvisoryCountry = {setEAdvisoryCountry}
              requestType = {requestType}
              onClickHandle = {() => handleEAdvisorySubmit()}
              onClose = {() => setOpenCountryDialog(false)}
          />
        </Card>
      </Grid>

    </Grid>
  } /></Box>)
}

export default QuickSearch
