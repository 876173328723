import { Box } from '@material-ui/core'
import React, {useContext, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormContext, useWatch } from 'react-hook-form'

import { OutlinedButton } from 'src/components/button'
import { components } from 'cng-web-lib'
import {LandedCostContext} from "../../../../../store/intelligent-advisory";
const {
  button: { CngPrimaryButton }
} = components


export const currencyAdornment = (form, key = 'currencySymbol') =>
  form && form.getValues(key) &&
    <Box color='text.secondary'
      mt={2.25} mr={.5}
    >
      {form.getValues(key)}
    </Box>

export const ResetButton = ({onClick, activeStep, ...props}) => {

  const form = useFormContext()

  return <OutlinedButton
    customColor='text.error'
    type='reset'
    startIcon={<FontAwesomeIcon icon={['fal', 'redo']} />}
    {...props}
    onClick={() => {
      let formValues = { ...form.getValues() }
      if(activeStep == 1) {
        form.reset({
          "currencySymbol": formValues.currencySymbol,
          "exportCountry": formValues.exportCountry,
          "importCountry": formValues.importCountry,
          "manufactureOrigin": formValues.manufactureOrigin,
          "check_hsCode": formValues.check_hsCode,
          "hsItem": formValues.hsItem,
          "hsCode": formValues.hsCode,
          "measures": formValues.measures,
          "incoterm": formValues.incoterm,
          "currencyCode": formValues.currencyCode,
          "merchandiseValue": formValues.merchandiseValue,
          "shipment": formValues.shipment,
          "oneShipmentPerPersonPerDay": formValues.oneShipmentPerPersonPerDay,
          "mot": formValues.mot,
          "importDate": formValues.importDate,
          "exportCountry_label": formValues.exportCountry_label,
          "importCountry_label": formValues.importCountry_label,
          "manufactureOrigin_label": formValues.manufactureOrigin_label,
          "isValid": formValues.isValid,
          "uomList": formValues.uomList,
          "freightInlandOrigin": "",
          "freightInlandDestination": "",
          "freightInternational": "",
          "originCharge": "",
          "destinationCharge": "",
          "insurance": "" })
      } else if (activeStep == 0) {
        form.reset({
          "freightInlandOrigin": formValues.freightInlandOrigin,
          "freightInlandDestination": formValues.freightInlandDestination,
          "freightInternational": formValues.freightInternational,
          "originCharge": formValues.originCharge,
          "destinationCharge": formValues.destinationCharge,
          "insurance": formValues.insurance,
          "currencySymbol": "",
          "exportCountry": "",
          "importCountry": "",
          "manufactureOrigin": "",
          "check_hsCode": "",
          "hsItem": null,
          "hsCode": "",
          "measures": [],
          "incoterm": "",
          "currencyCode": "",
          "merchandiseValue": "",
          "shipment": false,
          "oneShipmentPerPersonPerDay": "",
          "mot": "",
          "importDate": "",
          "exportCountry_label": "",
          "importCountry_label": "",
          "manufactureOrigin_label": "" })
      }

      console.log(form.getValues())

      if (onClick)
        onClick()
    }}
  >
    Reset
  </OutlinedButton>
}

export const NextButton = ({isLastStep, onStep, ...props}) => {
  const {
    editCriteria, setEditCriteria
  } = useContext(LandedCostContext)

  const { formState, ...form } = useFormContext()
  //To watch the form value, it will re-render when that particular value change
  const isValid = useWatch({name:'isValid'})
  const measures = useWatch({name: 'measures'})

  useEffect(() => {
    if(editCriteria) {
      form.trigger().then(isValid => {
        if(isValid)
          setEditCriteria(false)
      })
    }
  }, [editCriteria])

  //Prevent enabling of the next button after user attempts to re-validate the hs code
  function hasMeasure(measure){
    for (const item of measure){
      if(item.measureQuantity == ""){
        return true
      }
    }
    return false
  }

  const handleClick = () => {
    if (!isLastStep) {
      form.trigger().then(isValid => {
        if (!isValid)
          return;
        let values = form.getValues()
        if (!values.hsItem)
          form.setError('hsItem', 'Please select a HS code.')
        else {
          form.setValue('hsCode', values.hsItem.hs_code)
          // go to next page
          onStep()
        }
      })
    } else
      { onStep(form.getValues()) }
  }

  return <CngPrimaryButton type='submit'
    {...props}
    type='button'
    onClick={handleClick}
    disabled={props.disabled || !formState.isValid || !isValid || hasMeasure(measures)}
  />
}
