import {
  Box,
  Button,
  Grid,

  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'

import { formatText, formatColors } from '../helper'
import { components, constants, useServices } from 'cng-web-lib';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';

const useStyles = makeStyles(theme => ({
  color: {
    fontWeight: 'bold',
    color: theme.palette.text.error
  },
  docButton: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    background: 'rgba(28, 29, 33, 0.06)'
  },
  btnWrap: {
    overflowWrap: 'anywhere'
  }
}))
function DocContent(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const { securedSendRequest } = useServices()

  const [isLoading, setIsLoading] = useState(false)

  const handleFileOpen = link => {
    setIsLoading(true)
    securedSendRequest.execute(
      'post', link, {},
      res => {
        var dataBlob = new Blob([res.data] , {type: 'application/pdf'});
        if (!dataBlob.size)
          throw new Error('No data')
        var url = URL.createObjectURL(dataBlob);
        window.open(url, "_blank");
      },
      err => {
        enqueueSnackbar(err.toString(), { variant: 'error' })
      },
      done => setIsLoading(false),
      { responseType: 'arraybuffer' }
    )
  }

  return (<>
    <span dangerouslySetInnerHTML={{
      __html: formatColors(props.description, classes.color, true, 'NOTE:')
    }} />
    <br/><br/>
    {props.content.map((c, i) => (<React.Fragment key={i}>
      <b>{c.title}:</b>&ensp;
      <span dangerouslySetInnerHTML={{
        __html: c.value
      }} />
      <br/>
    </React.Fragment>))}
    {props.downloads && (<>
      <br/>
      <b>Downloads:</b><br/>
      <Box component={Grid} container spacing={1} pt={1}>
        {props.downloads.map((dl, i) =>
          <Grid item key={i}>

            <Button
              href={!dl.isInternal ? dl.link : null}
              target='_blank'
              onClick={() => dl.isInternal && handleFileOpen(dl.link)}
              variant='contained'
              classes={{
                root: classes.docButton,
                label: classes.btnWrap
              }}
              startIcon={<FontAwesomeIcon icon={['fal', dl.icon]} />}
              endIcon={isLoading && <FontAwesomeIcon icon={['fal', 'spinner-third']} spin />}
              disabled={isLoading}
            >
              {dl.name}
            </Button>
          </Grid>
        )}
      </Box>
    </>)}
  </>)
}

export default DocContent
