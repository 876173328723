import {
  Box,
  Chip,
  Grid,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const withAdornment = (extra, text, extraEnd) => (<Grid container spacing={1} alignItems='center'>
  {extra && <Grid item>
    {extra}
  </Grid>}
  <Grid item xs>
    <Typography variant='body2'>{text}</Typography>
  </Grid>
  {extraEnd && <Grid item>
    {extraEnd}
  </Grid>}
</Grid>)

export const IconOption = props => withAdornment(
  <Box color='text.textSecondary' display='inline-block'>
    <FontAwesomeIcon icon={['fal', props.icon]} fixedWidth />
  </Box>,
  props.text || props.children
)

const useChipStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.divider,
    fontSize: '.75em',
    minWidth: '5em'
  }
}))

export function ChipOption(props) {
  const classes = useChipStyles()

  return withAdornment(
    <Chip size='small'
      label={props.chip}
      className={classes.chip}
      variant='outlined'
    />,
    props.text || props.children
  )
}

const useTextStyles = makeStyles(theme => ({
  text: {
    fontWeight: 'bold',
    fontSize: '.95em'
  }
}))

export function TextOption(props) {
  const classes = useTextStyles()

  const starter = props.start && (<Box className={classes.text}>
    {props.start}
  </Box>)
  return withAdornment(
    props.start && (<Box className={classes.text} color='text.textSecondary'>
      {props.start}
    </Box>),
    props.text || props.children,
    props.end && (<Box className={classes.text} color='text.secondary'>
      {props.end}
    </Box>)
  )
}
